import { Injectable, inject } from '@angular/core';
import {
  PROCEDURE_OVERLAY_MODE,
  type ProcedureOverlayMode,
} from '../../tokens/procedure-overlay-mode/procedure-overlay-mode.token';
import { Content } from '../../types/flights-content.types';
import { FlightsContentService } from '../flights-content/flights-content.service';

@Injectable()
export class ProcedureOverlayService {
  private readonly procedureOverlayMode = inject(PROCEDURE_OVERLAY_MODE);
  private readonly flightsContentService = inject(FlightsContentService);

  show(mode: ProcedureOverlayMode): void {
    this.procedureOverlayMode.set(mode);
    this.flightsContentService.push(Content.Procedures);
  }
}
