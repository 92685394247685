import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { environment } from "@environment";
import {
  AltitudeSurveyUnifiedRequest,
  AltitudeSurveyUnifiedResponse,
  Route,
  RouteRequest,
  UnifiedTokenRequest,
  UnifiedTokenResponse,
} from "@generated/flight-route-service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FlightsUnauthenticatedService {
  private readonly http = inject(HttpClient);

  getAltitudeData(request: AltitudeSurveyUnifiedRequest): Observable<AltitudeSurveyUnifiedResponse> {
    return this.http.post<AltitudeSurveyUnifiedResponse>(
      `${environment.garmin.aviation.workerApiHost}/flights/altitude-survey-unauthenticated`,
      request
    );
  }

  getUnifiedTokenResponse(request: UnifiedTokenRequest): Observable<UnifiedTokenResponse> {
    return this.http.post<UnifiedTokenResponse>(
      `${environment.garmin.aviation.workerApiHost}/flights/token-search-unauthenticated`,
      request
    );
  }

  getRoute(request: RouteRequest): Observable<Route> {
    return this.http.post<Route>(
      `${environment.garmin.aviation.workerApiHost}/flights/create-unauthenticated`,
      request
    );
  }
}
