<fly-card
  class="search-card"
  [padding]="'reduced'">
  <div class="wrapper">

    <div class="airport-info">
      @if (airport().icon == null || airport().icon.length === 0) {
        <fly-icon [icon]="FlightIcons.Airports.ToweredServices"></fly-icon>
      } @else {
        <!-- Unique airport icon -->
        <img alt="runway diagram"
          src="data:image/svg+xml;base64, {{airport().icon}}">
      }

      <div class="airport-data">
        <div class="airport-row">
          <span class="airport-text" >{{ airport().icao }} - {{ airport().name | flyExtendedTitleCase }}</span>
          <div class="wx-conditions">
            <svg xmlns="http://www.w3.org/2000/svg"
              height="15"
              width="14"
              viewBox="0 0 14 15"
              fill="none">
              <circle cx="7"
                cy="7.5"
                r="7"
                [ngClass]="'condition-' + airport().category | flyReplace:' ':'-'"
                fill="currentColor"></circle>
            </svg>
            <span>{{ airport().category }}</span>
          </div>
        </div>

        <span class="location body-3">
          {{ location() }}
        </span>
      </div>
    </div>
  </div>
</fly-card>
