import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const WPT_FORMATS = {
  DEG: 'hddd.dddddd°',
  DEG_MIN: 'hddd°mm.mmm\'',
  DEG_MIN_SEC: 'hddd°mm\'ss.s\"'
};

export class LatLonUtils {
  // validation for degrees minutes and seconds
  static latValidator(formatType: string, inputType: string): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
      if (inputType === "min") {
        return this.validateMin(formatType, control.value);
      } else if (inputType === "sec") {
        return this.validateSec(control.value);
      } else {
        if (Number(control.value.substring(1, control.value.length)) < 0 || Number(control.value.substring(1, control.value.length)) > 90) {
          return { range: true };
        }
        if (formatType === 'hddd.dddddd°') {
          if (control.value.match(/^([ns](0?0?\d|0?[1-8]\d|0?90)(\.\d{1,6})?)?$/i) == null) {
            return { invalidFormat: true };
          }
        } else {
          if (control.value.match(/^([ns](0?0?\d|0?[1-8]\d|0?90))?$/i) == null) {
            return { invalidFormat: true };
          }
        }
      }
      return null;
    };
  }

  static lonValidator(formatType: string, inputType: string): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
      if (inputType === "min") {
        return this.validateMin(formatType, control.value);
      } else if (inputType === "sec") {
        return this.validateSec(control.value);
      } else {
        if (Number(control.value.substring(1, control.value.length)) < 0 || Number(control.value.substring(1, control.value.length)) > 180) {
          return { range: true };
        }
        if (formatType === 'hddd.dddddd°') {
          if (control.value.match(/^([ew](0?0?\d|0?[1-9]\d|1[0-7]\d|180)(\.\d{1,6})?)?$/i) == null) {
            return { invalidFormat: true };
          }
        } else {
          if (control.value.match(/^([ew](0?0?\d|0?[1-9]\d|1[0-7]\d|180))?$/i) == null) {
            return { invalidFormat: true };
          }
        }
      }
      return null;
    };
  }

  static validateMin(formatType: string, value: any): ValidationErrors | null {
    if (Number(value) < 0 || Number(value) > 60) {
      return { range: true };
    }
    if (formatType === WPT_FORMATS.DEG_MIN) {
      if (value.match(/^((0?\d|[1-5]\d|60)(.\d{1,3})?)?$/i) == null) {
        return { invalidFormat: true };
      }
    } else {
      if (value.match(/^(0?\d|[1-5]\d|60)?$/i) == null) {
        return { invalidFormat: true };
      }
    }
    return null;
  }

  static validateSec(value: any): ValidationErrors | null {
    if (Number(value) < 0 || Number(value) > 60) {
      return { range: true };
    }
    if (value.match(/^((0?\d|[1-5]\d|60)(.\d)?)?$/i) == null) {
      return { invalidFormat: true };
    }
    return null;
  }

  static convertToDegrees(deg: string, min: string, sec: string): number | undefined {
    if (deg === '' && min === '' && sec === '') {
      return;
    }
    const degreeNum = Number(deg.substring(1, deg.length)) + Number(min)/60 + Number(sec)/(60*60);
    return ((deg.substring(0, 1) === "N" || deg.substring(0, 1) === "E") ? 1 : -1) * degreeNum;
  }

  /**
   * Convert decimal degree value to DMS https://stackoverflow.com/a/5786281
   * @param D degree value in decimal
   * @param lng boolean flag denoting whether value is longitude (vs. latitude)
   * @returns an object with direction, degrees, minutes, seconds
   */
  static convertDecimalToDMS(D: number, lng: boolean): {
    dir: string,
    deg: number,
    min: number,
    sec: number
  } {
    let _D = D;
    return {
      dir: _D < 0 ? (lng ? "W" : "S") : lng ? "E" : "N",
      deg: 0 | (_D < 0 ? (_D = -_D) : _D),
      min: 0 | (((_D += 1e-9) % 1) * 60),
      sec: (0 | (((_D * 60) % 1) * 6000)) / 100,
    };
  }

  static splitDegrees(latLonNum: string): string[] {
    const splitArr = latLonNum.split(/['"°]/g);
    let i = splitArr.length;
    for (i; i <= 3; i++) {
      splitArr.push("");
    }
    return splitArr;
  }

  static maxLatValidator(): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
      const lat = this.convertToDegrees(control.value['latDeg'], control.value['latMin'], control.value['latSec']);
      if (lat != null && Math.abs(lat) > 90) {
        return { latTooBig: true };
      }
      return null;
    };
  }

  static maxLonValidator(): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
      const lon = this.convertToDegrees(control.value['lonDeg'], control.value['lonMin'], control.value['lonSec']);
      if (lon != null && Math.abs(lon) > 180) {
        return { lonTooBig: true };
      }
      return null;
    };
  }

  // Converts a lat/lon pair into a string of the form [NS]lat°[EW]lon° with whole numbers and leading zeroes.
  static getSimpleCoordinateString(lat: number, lon: number): string {
    const latDir = lat == null || lat >= 0 ? 'N' : 'S';
    const latString = Math.abs(Math.round(lat ?? 0)).toString();
    const lonDir = lon == null || lon >= 0 ? 'E' : 'W';
    const lonString = Math.abs(Math.round(lon ?? 0)).toString();
    return `${latDir}${latString.padStart(2, '0')}\u00B0${lonDir}${lonString.padStart(3, '0')}\u00B0`;
  }
}
