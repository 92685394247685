import { Injectable, computed, signal } from '@angular/core';
import { Content } from '../../types/flights-content.types';

@Injectable()
export class FlightsContentService {
  private readonly _contentStack = signal([Content.Plan]);

  readonly content = computed(() => this._contentStack().at(-1) as Content);

  push(content: Content): void {
    this._contentStack.update((stack) => [...stack, content]);
  }

  pop(): void {
    if (this._contentStack().length === 1) throw new Error('content stack cannot be emptied');

    this._contentStack.update((stack) => stack.slice(0, -1));
  }

  set(stack: Content[]): void {
    if (stack.length === 0) throw new Error('content stack cannot be empty');

    this._contentStack.set(stack);
  }
}
