/**
 * FlightOrchestratorService
 * flightOrchestratorService
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-ProdExp-Cloud-Connectivity@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The filed FlightPlan associated with the flight (if there is one). This represents the data sent to the filing authorities.
 */
export interface FlightPlan { 
    /**
     * Primary Key
     */
    id?: string;
    /**
     * The date the flight plan was deleted
     */
    deletedAt?: string;
    /**
     * The date the flight plan was created
     */
    createdAt?: string;
    /**
     * The date the flight plan was last updated
     */
    updatedAt?: string;
    /**
     * UUID of this flight plan
     */
    flightPlanId?: string;
    /**
     * Version of the flight plan id
     */
    flightPlanVersion?: number;
    /**
     * The UUID referencing a Garmin customer
     */
    customerGuid?: string;
    /**
     * The state of this flight plan in relation to filing.
     */
    flightPlanState?: FlightPlanFlightPlanState;
    /**
     * The previously achieved states for this filing. Includes the current flight plan state.
     */
    achievedFlightPlanStates?: Array<FlightPlanAchievedFlightPlanStates>;
    /**
     * Space delimited list of aftn addresses to file this flight plan with.
     */
    aftnAddresses?: string;
    /**
     * Whether or not the flight plan contains the data required to be filed.
     */
    isFileable?: boolean;
    /**
     * The type of form used to file.
     */
    formType?: FlightPlanFormType;
    /**
     * STAY indicator
     */
    stayInfo1?: string;
    /**
     * STAY indicator
     */
    stayInfo2?: string;
    /**
     * STAY indicator
     */
    stayInfo3?: string;
    /**
     * STAY indicator
     */
    stayInfo4?: string;
    /**
     * STAY indicator
     */
    stayInfo5?: string;
    /**
     * STAY indicator
     */
    stayInfo6?: string;
    /**
     * STAY indicator
     */
    stayInfo7?: string;
    /**
     * STAY indicator
     */
    stayInfo8?: string;
    /**
     * STAY indicator
     */
    stayInfo9?: string;
    /**
     * STS indicator.
     */
    specialStatus?: string;
    /**
     * RMK indicator
     */
    remarks?: string;
    /**
     * RFP indicator
     */
    replacementFlightPlan?: string;
    /**
     * User specified AFTN addresses. Space delimited.
     */
    userAftnAddresses?: string;
    /**
     * The scheduled time to file this flight plan with the applicable filing providers.
     */
    timeToFile?: string;
    /**
     * Pre-departure clearance text.
     */
    preDepartureClearance?: string;
    /**
     * Canada VFR only. Number of seconds after ETA in which a search and rescue operation will be issued. Minimum 1 min, maximum 24 hours.
     */
    sarTime?: number;
    /**
     * Enum referencing the type of flight
     */
    icaoFlightType?: FlightPlanIcaoFlightType;
    /**
     * Enum referencing the flight rules for this flight.
     */
    flightRules: FlightPlanFlightRules;
    /**
     * Estimated departure time.
     */
    etd: string;
    /**
     * DOF indicator
     */
    dateOfFlight?: string;
    /**
     * Canada VFR only. Enum referencing the type of flight when filing Canadian VFR.
     */
    canadianFlightType?: FlightPlanCanadianFlightType;
    /**
     * Tail number for the aircraft.
     */
    aircraftTail: string;
    /**
     * Aircraft wake turbulence.
     */
    aircraftWakeTurb: FlightPlanAircraftWakeTurb;
    /**
     * The home base of the aircraft.
     */
    aircraftBase?: string;
    /**
     * List of aircraft colors.
     */
    aircraftColor?: Array<string>;
    /**
     * Single character containing the Secondary Surveillance Radar (SSR) mode.
     */
    ssrMode?: string;
    /**
     * 4 numeric Secondary Surveillance Radar (SSR) code.
     */
    ssrCode?: string;
    /**
     * Aircraft Registration (REG).
     */
    aircraftReg?: string;
    /**
     * The aircraft equipment code(s) entered by the user.
     */
    aircraftEquipment: string;
    /**
     * The aircraft surveillance equipment code(s) entered by the user.
     */
    aircraftSurveillanceEquipment: string;
    /**
     * Whether or not a ballistic parachute is installed on the aircraft. Optional for Canadian flight plan filing.
     */
    aircraftBallisticPara?: boolean;
    /**
     * Radio call sign for the flight.
     */
    callSign?: string;
    /**
     * Number of people onboard during the flight.
     */
    peopleOnBoard?: number;
    /**
     * Radio communication, navigation and approach aid equipment and capabilities.
     */
    survivalRadios?: Array<FlightPlanSurvivalRadios>;
    /**
     * These are supplemental survival remarks to include in item 19 for filing.
     */
    survivalRemarks?: string;
    /**
     * Enum referencing the type of jackets on board. Any combination of values.
     */
    jacketTypes?: Array<FlightPlanJacketTypes>;
    /**
     * Enum referencing the types of survival equipment on board. Any combination of values.
     */
    survivalEquipment?: Array<FlightPlanSurvivalEquipment>;
    /**
     * Number of dinghies on board.
     */
    dinghyCount?: number;
    /**
     * Total person capacity of all dinghies on board.
     */
    dinghiesCapacity?: number;
    /**
     * Color of the dinghies on board. Free text string.
     */
    dinghiesColor?: string;
    /**
     * Whether or not the dinghies are covered.
     */
    dinghiesCovered?: boolean;
    /**
     * PBN indicator
     */
    perfBasedNav?: string;
    /**
     * NAV indicator
     */
    navEquipment?: string;
    /**
     * COM indicator
     */
    commEquipment?: string;
    /**
     * DAT indicator
     */
    dataLink?: string;
    /**
     * SUR indicator
     */
    surveillance?: string;
    /**
     * SEL indicator
     */
    selcal?: string;
    /**
     * TYP indicator
     */
    aircraftType: string;
    /**
     * CODE indicator
     */
    code?: string;
    /**
     * OPR indicator
     */
    aircraftOperator?: string;
    /**
     * PER indicator
     */
    aircraftPerformance?: string;
    /**
     * RVR indicator that is expected as a 1-3 digit integer in meters
     */
    runwayVisRange?: string;
    /**
     * ORGN indicator
     */
    orgnIndicator?: string;
    /**
     * Whether or not the airplane is capable of receiving CDRs from ATC.
     */
    isCdrCapable?: boolean;
    /**
     * The aircraft count associated with this flight plan. Valid values: 1-99. Can be null.
     */
    numberOfAircraft?: number;
    /**
     * The landing gear type of the aircraft. Required for Canadian flight plans.
     */
    aircraftLandingGear?: FlightPlanAircraftLandingGear;
    /**
     * Required when filing Canadian VFR. Contact information (name) of persons/company to be notified if SAR action is initiated
     */
    sarNotificationName?: string;
    /**
     * Required when filing Canadian VFR. Contact information (phone number) of persons/company to be notified if SAR action is initiated
     */
    sarNotificationPhone?: string;
    /**
     * Required when filing Canadian VFR. Contact information (address) of persons/company to be notified if SAR action is initiated
     */
    sarNotificationAddress?: string;
    /**
     * Only required for Canadian VFR when the Flight\'s survival_radios contains \'E\' for ELT. Emergency Locator Transmitter Type.
     */
    eltType?: FlightPlanEltType;
    /**
     * The route\'s overall cruising speed in knots.
     */
    cruisingSpeedInKnots: number;
    /**
     * The route\'s overall cruising flight level. In hundreds of feet (aka flight level).
     */
    cruisingFlightLevel: number;
    /**
     * The identifier for the first alternate destination.
     */
    altDest1Identifier?: string;
    /**
     * Enum of the type of the alternate destination.
     */
    altDest1Type?: FlightPlanAltDest1Type;
    altDest1Lat?: number;
    altDest1Lon?: number;
    altDest1CountryCode?: string;
    altDest1Navaid?: string;
    altDest1Distance?: number;
    altDest1Bearing?: number;
    /**
     * The identifier for the second alternate destination.
     */
    altDest2Identifier?: string;
    /**
     * Enum of the type of the alternate destination.
     */
    altDest2Type?: FlightPlanAltDest2Type;
    altDest2Lat?: number;
    altDest2Lon?: number;
    altDest2CountryCode?: string;
    altDest2Navaid?: string;
    altDest2Distance?: number;
    altDest2Bearing?: number;
    /**
     * Estimated enroute time for this route (ETE). In hours.
     */
    estTimeEnroute: string;
    /**
     * Total fuel duration of the aircraft for this route. In hours.
     */
    fuelDuration: number;
    /**
     * DLE indicator
     */
    enrouteDelay?: string;
    /**
     * RALT indicator
     */
    enrouteAlt?: string;
    /**
     * TALT indicator
     */
    takeoffAlt?: string;
    /**
     * RIF indicator
     */
    reclearanceInFlt?: string;
    /**
     * Whether SFRA departure gateway information is needed along the route.
     */
    sfraDepartInfoNeeded?: boolean;
    /**
     * Whether SFRA arrival gateway information is needed along the route.
     */
    sfraArriveInfoNeeded?: boolean;
    /**
     * If the route associated with this flight is a TEC route, this will be populated with the TEC route name.
     */
    tecRouteName?: string;
    /**
     * The phone number includes any country code, area code, and phone number.
     */
    pilotPhone: string;
    /**
     * This should have a limited set of characters. For filing, this name can be \'simplified\' programmatically.
     */
    pilotFilingUserName: string;
    /**
     * The timestamp representing when the pilot\'s phone number was verified.
     */
    pilotPhoneVerifiedDate?: string;
    /**
     * Required to file in Canada. The pilot\'s mailing address.
     */
    pilotCertificateNumber?: string;
    /**
     * Required to file in Canada. The pilot\'s mailing address.
     */
    pilotAddress?: string;
    /**
     * Required to file in Canada. This specifies the contact information for where the pilot will deliver their arrival report.
     */
    arrivalContact?: string;
    /**
     * Whether or not the current flight plan and associated rows in other tables are under admin control.
     */
    isUnderAdminControl?: boolean;
    /**
     * Complete EET/ String for use in item18, when populated.
     */
    item18EETOverride?: string;
    /**
     * Complete TYP/ String for use in item18, when populated.
     */
    item18TYPOverride?: string;
    /**
     * Expected to be populated for flight plans originating from test cases. A unique identifier for the environment the test cases are running in.
     */
    testCaseEnvId?: string;
    /**
     * If true, then this entry is marked for GDPR deletion, and everything associated (Provider Messages, etc) with this flightPlanID will be purged when the next GDPR deletion request arrives
     */
    markedForGdprDeletion?: boolean;
    /**
     * Optional. The reason why flight_plan_state has its current value.
     */
    flightPlanStateReason?: string;
    /**
     * Which provider environment the flight plan is filed with.
     */
    providerEnvironment?: FlightPlanProviderEnvironment;
}
export enum FlightPlanFlightPlanState {
    CREATED = 'CREATED',
    SCHEDULED = 'SCHEDULED',
    DATA_UPDATE_NEEDED = 'DATA_UPDATE_NEEDED',
    FILE_SUBMITTED = 'FILE_SUBMITTED',
    FILE_ACK_RECEIVED = 'FILE_ACK_RECEIVED',
    FILE_REJ_RECEIVED = 'FILE_REJ_RECEIVED',
    FILED = 'FILED',
    POST_FILED_ACK_NOTIFIED = 'POST_FILED_ACK_NOTIFIED',
    POST_FILED_REJ_NOTIFIED = 'POST_FILED_REJ_NOTIFIED',
    NOT_FILED = 'NOT_FILED',
    MODIFY_SUBMITTED = 'MODIFY_SUBMITTED',
    MODIFY_ACK_RECEIVED = 'MODIFY_ACK_RECEIVED',
    MODIFY_REJ_RECEIVED = 'MODIFY_REJ_RECEIVED',
    MODIFIED = 'MODIFIED',
    POST_MODIFIED_ACK_NOTIFIED = 'POST_MODIFIED_ACK_NOTIFIED',
    POST_MODIFIED_REJ_NOTIFIED = 'POST_MODIFIED_REJ_NOTIFIED',
    NOT_MODIFIED = 'NOT_MODIFIED',
    CANCEL_SUBMITTED = 'CANCEL_SUBMITTED',
    CANCEL_ACK_RECEIVED = 'CANCEL_ACK_RECEIVED',
    CANCEL_REJ_RECEIVED = 'CANCEL_REJ_RECEIVED',
    CANCELED = 'CANCELED',
    POST_CANCELED_ACK_NOTIFIED = 'POST_CANCELED_ACK_NOTIFIED',
    POST_CANCELED_REJ_NOTIFIED = 'POST_CANCELED_REJ_NOTIFIED',
    NOT_CANCELED = 'NOT_CANCELED',
    ACTIVATE_SUBMITTED = 'ACTIVATE_SUBMITTED',
    ACTIVATE_ACK_RECEIVED = 'ACTIVATE_ACK_RECEIVED',
    ACTIVATE_REJ_RECEIVED = 'ACTIVATE_REJ_RECEIVED',
    ACTIVATED = 'ACTIVATED',
    POST_ACTIVATED_ACK_NOTIFIED = 'POST_ACTIVATED_ACK_NOTIFIED',
    POST_ACTIVATED_REJ_NOTIFIED = 'POST_ACTIVATED_REJ_NOTIFIED',
    NOT_ACTIVATED = 'NOT_ACTIVATED',
    ACTIVATE_OVERDUE_NOTIFIED = 'ACTIVATE_OVERDUE_NOTIFIED',
    ACTIVATE_OVERDUE = 'ACTIVATE_OVERDUE',
    CLOSE_SUBMITTED = 'CLOSE_SUBMITTED',
    CLOSE_ACK_RECEIVED = 'CLOSE_ACK_RECEIVED',
    CLOSE_REJ_RECEIVED = 'CLOSE_REJ_RECEIVED',
    CLOSED = 'CLOSED',
    POST_CLOSED_ACK_NOTIFIED = 'POST_CLOSED_ACK_NOTIFIED',
    POST_CLOSED_REJ_NOTIFIED = 'POST_CLOSED_REJ_NOTIFIED',
    NOT_CLOSED = 'NOT_CLOSED',
    CLOSE_OVERDUE_NOTIFIED = 'CLOSE_OVERDUE_NOTIFIED',
    CLOSE_OVERDUE = 'CLOSE_OVERDUE',
    ARCHIVED = 'ARCHIVED',
    EXPIRED = 'EXPIRED'
};
export enum FlightPlanAchievedFlightPlanStates {
    CREATED = 'CREATED',
    SCHEDULED = 'SCHEDULED',
    DATA_UPDATE_NEEDED = 'DATA_UPDATE_NEEDED',
    FILE_SUBMITTED = 'FILE_SUBMITTED',
    FILE_ACK_RECEIVED = 'FILE_ACK_RECEIVED',
    FILE_REJ_RECEIVED = 'FILE_REJ_RECEIVED',
    FILED = 'FILED',
    POST_FILED_ACK_NOTIFIED = 'POST_FILED_ACK_NOTIFIED',
    POST_FILED_REJ_NOTIFIED = 'POST_FILED_REJ_NOTIFIED',
    NOT_FILED = 'NOT_FILED',
    MODIFY_SUBMITTED = 'MODIFY_SUBMITTED',
    MODIFY_ACK_RECEIVED = 'MODIFY_ACK_RECEIVED',
    MODIFY_REJ_RECEIVED = 'MODIFY_REJ_RECEIVED',
    MODIFIED = 'MODIFIED',
    POST_MODIFIED_ACK_NOTIFIED = 'POST_MODIFIED_ACK_NOTIFIED',
    POST_MODIFIED_REJ_NOTIFIED = 'POST_MODIFIED_REJ_NOTIFIED',
    NOT_MODIFIED = 'NOT_MODIFIED',
    CANCEL_SUBMITTED = 'CANCEL_SUBMITTED',
    CANCEL_ACK_RECEIVED = 'CANCEL_ACK_RECEIVED',
    CANCEL_REJ_RECEIVED = 'CANCEL_REJ_RECEIVED',
    CANCELED = 'CANCELED',
    POST_CANCELED_ACK_NOTIFIED = 'POST_CANCELED_ACK_NOTIFIED',
    POST_CANCELED_REJ_NOTIFIED = 'POST_CANCELED_REJ_NOTIFIED',
    NOT_CANCELED = 'NOT_CANCELED',
    ACTIVATE_SUBMITTED = 'ACTIVATE_SUBMITTED',
    ACTIVATE_ACK_RECEIVED = 'ACTIVATE_ACK_RECEIVED',
    ACTIVATE_REJ_RECEIVED = 'ACTIVATE_REJ_RECEIVED',
    ACTIVATED = 'ACTIVATED',
    POST_ACTIVATED_ACK_NOTIFIED = 'POST_ACTIVATED_ACK_NOTIFIED',
    POST_ACTIVATED_REJ_NOTIFIED = 'POST_ACTIVATED_REJ_NOTIFIED',
    NOT_ACTIVATED = 'NOT_ACTIVATED',
    ACTIVATE_OVERDUE_NOTIFIED = 'ACTIVATE_OVERDUE_NOTIFIED',
    ACTIVATE_OVERDUE = 'ACTIVATE_OVERDUE',
    CLOSE_SUBMITTED = 'CLOSE_SUBMITTED',
    CLOSE_ACK_RECEIVED = 'CLOSE_ACK_RECEIVED',
    CLOSE_REJ_RECEIVED = 'CLOSE_REJ_RECEIVED',
    CLOSED = 'CLOSED',
    POST_CLOSED_ACK_NOTIFIED = 'POST_CLOSED_ACK_NOTIFIED',
    POST_CLOSED_REJ_NOTIFIED = 'POST_CLOSED_REJ_NOTIFIED',
    NOT_CLOSED = 'NOT_CLOSED',
    CLOSE_OVERDUE_NOTIFIED = 'CLOSE_OVERDUE_NOTIFIED',
    CLOSE_OVERDUE = 'CLOSE_OVERDUE',
    ARCHIVED = 'ARCHIVED',
    EXPIRED = 'EXPIRED'
};
export enum FlightPlanFormType {
    ICAO = 'ICAO',
    CANADIAN = 'CANADIAN'
};
export enum FlightPlanIcaoFlightType {
    GENERAL = 'GENERAL',
    MILITARY = 'MILITARY',
    NON_SCHEDULED = 'NON_SCHEDULED',
    SCHEDULED = 'SCHEDULED',
    OTHER = 'OTHER'
};
export enum FlightPlanFlightRules {
    VFR = 'VFR',
    IFR = 'IFR',
    YFR = 'YFR',
    ZFR = 'ZFR',
    SFRA = 'SFRA'
};
export enum FlightPlanCanadianFlightType {
    CVFR = 'CVFR',
    DVFR_FP = 'DVFR_FP',
    DVFR_FI_OR_MILITARY = 'DVFR_FI_OR_MILITARY',
    FLIGHT_ITINERARY_OR_SCHEDULED = 'FLIGHT_ITINERARY_OR_SCHEDULED',
    GENERAL_AVIATION = 'GENERAL_AVIATION',
    NON_SCHEDULED = 'NON_SCHEDULED',
    OTHER = 'OTHER'
};
export enum FlightPlanAircraftWakeTurb {
    HEAVY = 'HEAVY',
    MEDIUM = 'MEDIUM',
    LIGHT = 'LIGHT'
};
export enum FlightPlanSurvivalRadios {
    UHF = 'UHF',
    VHF = 'VHF',
    ELT = 'ELT'
};
export enum FlightPlanJacketTypes {
    LIGHT = 'LIGHT',
    FLUORESCENT = 'FLUORESCENT',
    UHF = 'UHF',
    VHF = 'VHF'
};
export enum FlightPlanSurvivalEquipment {
    POLAR = 'POLAR',
    DESERT = 'DESERT',
    MARITIME = 'MARITIME',
    JUNGLE = 'JUNGLE'
};
export enum FlightPlanAircraftLandingGear {
    WHEELS = 'WHEELS',
    SKIS_SKIDS_SEAPLANE = 'SKIS_SKIDS_SEAPLANE',
    FLOATS = 'FLOATS',
    AMPHIBIAN = 'AMPHIBIAN',
    WHEELS_AND_SKIDS = 'WHEELS_AND_SKIDS'
};
export enum FlightPlanEltType {
    AUTOMATIC_EJECTABLE = 'AUTOMATIC_EJECTABLE',
    AUTOMATIC_DEPLOYABLE = 'AUTOMATIC_DEPLOYABLE',
    AUTOMATIC_FIXED = 'AUTOMATIC_FIXED',
    AUTOMATIC_PORTABLE = 'AUTOMATIC_PORTABLE',
    FIXED = 'FIXED',
    PERSONAL = 'PERSONAL',
    SURVIVAL = 'SURVIVAL',
    WATER_ACTIVATED = 'WATER_ACTIVATED',
    NONE = 'NONE'
};
export enum FlightPlanAltDest1Type {
    AIRPORT = 'AIRPORT',
    COORDINATE = 'COORDINATE',
    NDB = 'NDB',
    VOR = 'VOR',
    FIX_RADIAL_DISTANCE = 'FIX_RADIAL_DISTANCE',
    NON_ICAO = 'NON_ICAO',
    INTERSECTION = 'INTERSECTION',
    SFRA_GATE = 'SFRA_GATE',
    AIRWAY = 'AIRWAY',
    WAYPOINT = 'WAYPOINT',
    SID = 'SID',
    STAR = 'STAR'
};
export enum FlightPlanAltDest2Type {
    AIRPORT = 'AIRPORT',
    COORDINATE = 'COORDINATE',
    NDB = 'NDB',
    VOR = 'VOR',
    FIX_RADIAL_DISTANCE = 'FIX_RADIAL_DISTANCE',
    NON_ICAO = 'NON_ICAO',
    INTERSECTION = 'INTERSECTION',
    SFRA_GATE = 'SFRA_GATE',
    AIRWAY = 'AIRWAY',
    WAYPOINT = 'WAYPOINT',
    SID = 'SID',
    STAR = 'STAR'
};
export enum FlightPlanProviderEnvironment {
    FLIGHT_DOMAIN_NADIN = 'FLIGHT_DOMAIN_NADIN',
    FLIGHT_DOMAIN_NADIN_TEST = 'FLIGHT_DOMAIN_NADIN_TEST',
    FPSERVICES_LEIDOS = 'FPSERVICES_LEIDOS',
    FPSERVICES_LEIDOS_TEST = 'FPSERVICES_LEIDOS_TEST',
    FPSERVICES_EFPL = 'FPSERVICES_EFPL',
    FPSERVICES_EFPL_TEST = 'FPSERVICES_EFPL_TEST',
    FPSERVICES_ATRT = 'FPSERVICES_ATRT',
    FPSERVICES_ATRT_TEST = 'FPSERVICES_ATRT_TEST'
};



