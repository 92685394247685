import { InjectionToken, ValueProvider } from "@angular/core";
import { RouteComputedLeg } from "@generated/flight-orchestrator-service";
import { AirportSearchResult } from "@shared/models/airport/search/airport-search-result.model";
import { Subject } from "rxjs";

export interface AirportResponse {
  type: 'airport',
  response: AirportSearchResult,
}
export interface WaypointResponse {
  type: 'waypoint',
  response: RouteComputedLeg,
}
export type SearchBarEvent = AirportResponse | WaypointResponse | null;

export const SEARCH_BAR_EVENT = new InjectionToken<Subject<SearchBarEvent>>('SEARCH_BAR_EVENT');

export const provideSearchBarEvent = (): ValueProvider => ({
  provide: SEARCH_BAR_EVENT,
  useValue: new Subject(),
});
