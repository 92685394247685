/**
 * FlightOrchestratorService
 * flightOrchestratorService
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-ProdExp-Cloud-Connectivity@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { FilingStatusInfo } from '../model/filing-status-info.model';
// @ts-ignore
import { FlightChangeRequest } from '../model/flight-change-request.model';
// @ts-ignore
import { FlightRequest } from '../model/flight-request.model';
// @ts-ignore
import { FlightSearchParams } from '../model/flight-search-params.model';
// @ts-ignore
import { FlightV2 } from '../model/flight-v2.model';
// @ts-ignore
import { RouteBriefing } from '../model/route-briefing.model';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { FlightOrchestratorServiceConfiguration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class FlightV2ControllerService {

    protected basePath = 'https://avcloud-api-test.garmin.com/flightOrchestratorService';
    public defaultHeaders = new HttpHeaders();
    public configuration = new FlightOrchestratorServiceConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: FlightOrchestratorServiceConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Accept the ATC-proposed route
     * @param flightId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public acceptAtcProposedRoute(flightId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<FlightV2>;
    public acceptAtcProposedRoute(flightId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<FlightV2>>;
    public acceptAtcProposedRoute(flightId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<FlightV2>>;
    public acceptAtcProposedRoute(flightId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (flightId === null || flightId === undefined) {
            throw new Error('Required parameter flightId was null or undefined when calling acceptAtcProposedRoute.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/flights/${this.configuration.encodeParam({name: "flightId", value: flightId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/atc-proposed-route/accept`;
        return this.httpClient.request<FlightV2>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Accept the Pre-Departure Clearance (PDC) route
     * @param flightId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public acceptPdcRoute(flightId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<FlightV2>;
    public acceptPdcRoute(flightId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<FlightV2>>;
    public acceptPdcRoute(flightId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<FlightV2>>;
    public acceptPdcRoute(flightId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (flightId === null || flightId === undefined) {
            throw new Error('Required parameter flightId was null or undefined when calling acceptPdcRoute.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/flights/${this.configuration.encodeParam({name: "flightId", value: flightId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/pdc-route/accept`;
        return this.httpClient.request<FlightV2>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Attempts to Activate a previously filed flight plan.
     * @param flightId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateAFiledFlightPlan(flightId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<FlightV2>;
    public activateAFiledFlightPlan(flightId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<FlightV2>>;
    public activateAFiledFlightPlan(flightId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<FlightV2>>;
    public activateAFiledFlightPlan(flightId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (flightId === null || flightId === undefined) {
            throw new Error('Required parameter flightId was null or undefined when calling activateAFiledFlightPlan.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/flights/${this.configuration.encodeParam({name: "flightId", value: flightId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/activate`;
        return this.httpClient.request<FlightV2>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a Flight record
     * @param flightRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addResource(flightRequest: FlightRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<FlightV2>;
    public addResource(flightRequest: FlightRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<FlightV2>>;
    public addResource(flightRequest: FlightRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<FlightV2>>;
    public addResource(flightRequest: FlightRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (flightRequest === null || flightRequest === undefined) {
            throw new Error('Required parameter flightRequest was null or undefined when calling addResource.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/flights`;
        return this.httpClient.request<FlightV2>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: flightRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Archive/Soft-delete a flight and any associated routes/routePoints
     * @param flightId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public archiveFlight(flightId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any>;
    public archiveFlight(flightId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<any>>;
    public archiveFlight(flightId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<any>>;
    public archiveFlight(flightId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (flightId === null || flightId === undefined) {
            throw new Error('Required parameter flightId was null or undefined when calling archiveFlight.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/flights/${this.configuration.encodeParam({name: "flightId", value: flightId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Attempts to Cancel a previously filed flight plan.
     * @param flightId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelFlightPlanFiling(flightId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<FlightV2>;
    public cancelFlightPlanFiling(flightId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<FlightV2>>;
    public cancelFlightPlanFiling(flightId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<FlightV2>>;
    public cancelFlightPlanFiling(flightId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (flightId === null || flightId === undefined) {
            throw new Error('Required parameter flightId was null or undefined when calling cancelFlightPlanFiling.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/flights/${this.configuration.encodeParam({name: "flightId", value: flightId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/file/cancel`;
        return this.httpClient.request<FlightV2>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Attempts to Close a previously filed flight plan.
     * @param flightId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public closeAFiledFlightPlan(flightId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<FlightV2>;
    public closeAFiledFlightPlan(flightId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<FlightV2>>;
    public closeAFiledFlightPlan(flightId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<FlightV2>>;
    public closeAFiledFlightPlan(flightId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (flightId === null || flightId === undefined) {
            throw new Error('Required parameter flightId was null or undefined when calling closeAFiledFlightPlan.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/flights/${this.configuration.encodeParam({name: "flightId", value: flightId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/close`;
        return this.httpClient.request<FlightV2>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * File the flight with flight-id
     * @param flightId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fileFlight(flightId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<FlightV2>;
    public fileFlight(flightId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<FlightV2>>;
    public fileFlight(flightId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<FlightV2>>;
    public fileFlight(flightId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (flightId === null || flightId === undefined) {
            throw new Error('Required parameter flightId was null or undefined when calling fileFlight.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/flights/${this.configuration.encodeParam({name: "flightId", value: flightId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/file`;
        return this.httpClient.request<FlightV2>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the briefing report for a specified flight id
     * @param flightId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBriefingReport(flightId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<RouteBriefing>;
    public getBriefingReport(flightId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<RouteBriefing>>;
    public getBriefingReport(flightId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<RouteBriefing>>;
    public getBriefingReport(flightId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (flightId === null || flightId === undefined) {
            throw new Error('Required parameter flightId was null or undefined when calling getBriefingReport.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/flights/${this.configuration.encodeParam({name: "flightId", value: flightId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/briefing-report`;
        return this.httpClient.request<RouteBriefing>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a Flight by the flight-id
     * @param flightId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFlightById(flightId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<FlightV2>;
    public getFlightById(flightId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<FlightV2>>;
    public getFlightById(flightId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<FlightV2>>;
    public getFlightById(flightId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (flightId === null || flightId === undefined) {
            throw new Error('Required parameter flightId was null or undefined when calling getFlightById.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/flights/${this.configuration.encodeParam({name: "flightId", value: flightId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<FlightV2>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the filing status of a flight by its flight ID
     * @param flightId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFlightFilingStatus(flightId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<FilingStatusInfo>;
    public getFlightFilingStatus(flightId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<FilingStatusInfo>>;
    public getFlightFilingStatus(flightId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<FilingStatusInfo>>;
    public getFlightFilingStatus(flightId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (flightId === null || flightId === undefined) {
            throw new Error('Required parameter flightId was null or undefined when calling getFlightFilingStatus.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/flights/${this.configuration.encodeParam({name: "flightId", value: flightId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/status`;
        return this.httpClient.request<FilingStatusInfo>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Searches for flights by aircraft, with additional optional parameters
     * @param aircraftTail 
     * @param customerGuid 
     * @param startTimestamp 
     * @param endTimestamp 
     * @param maxCount 
     * @param filedOnly 
     * @param airplaneId 
     * @param latitude 
     * @param longitude 
     * @param maxLength 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFlightsForAircraft(aircraftTail: string, customerGuid?: string, startTimestamp?: string, endTimestamp?: string, maxCount?: number, filedOnly?: boolean, airplaneId?: string, latitude?: number, longitude?: number, maxLength?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<Array<FlightV2>>;
    public getFlightsForAircraft(aircraftTail: string, customerGuid?: string, startTimestamp?: string, endTimestamp?: string, maxCount?: number, filedOnly?: boolean, airplaneId?: string, latitude?: number, longitude?: number, maxLength?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<Array<FlightV2>>>;
    public getFlightsForAircraft(aircraftTail: string, customerGuid?: string, startTimestamp?: string, endTimestamp?: string, maxCount?: number, filedOnly?: boolean, airplaneId?: string, latitude?: number, longitude?: number, maxLength?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<Array<FlightV2>>>;
    public getFlightsForAircraft(aircraftTail: string, customerGuid?: string, startTimestamp?: string, endTimestamp?: string, maxCount?: number, filedOnly?: boolean, airplaneId?: string, latitude?: number, longitude?: number, maxLength?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (aircraftTail === null || aircraftTail === undefined) {
            throw new Error('Required parameter aircraftTail was null or undefined when calling getFlightsForAircraft.');
        }


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (customerGuid !== undefined && customerGuid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerGuid, 'customer-guid');
        }
        if (startTimestamp !== undefined && startTimestamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startTimestamp, 'start-timestamp');
        }
        if (endTimestamp !== undefined && endTimestamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endTimestamp, 'end-timestamp');
        }
        if (maxCount !== undefined && maxCount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>maxCount, 'max-count');
        }
        if (filedOnly !== undefined && filedOnly !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filedOnly, 'filed-only');
        }
        if (airplaneId !== undefined && airplaneId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>airplaneId, 'airplane-id');
        }
        if (latitude !== undefined && latitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>latitude, 'latitude');
        }
        if (longitude !== undefined && longitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>longitude, 'longitude');
        }
        if (maxLength !== undefined && maxLength !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>maxLength, 'max-length');
        }

        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/flights/aircraft/${this.configuration.encodeParam({name: "aircraftTail", value: aircraftTail, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Array<FlightV2>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Searches for flights by user, with additional optional parameters
     * @param customerGuid 
     * @param includeDeleted 
     * @param startTimestamp 
     * @param endTimestamp 
     * @param maxCount 
     * @param filedOnly 
     * @param pilotId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFlightsForUser(customerGuid: string, includeDeleted?: boolean, startTimestamp?: string, endTimestamp?: string, maxCount?: number, filedOnly?: boolean, pilotId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<Array<FlightV2>>;
    public getFlightsForUser(customerGuid: string, includeDeleted?: boolean, startTimestamp?: string, endTimestamp?: string, maxCount?: number, filedOnly?: boolean, pilotId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<Array<FlightV2>>>;
    public getFlightsForUser(customerGuid: string, includeDeleted?: boolean, startTimestamp?: string, endTimestamp?: string, maxCount?: number, filedOnly?: boolean, pilotId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<Array<FlightV2>>>;
    public getFlightsForUser(customerGuid: string, includeDeleted?: boolean, startTimestamp?: string, endTimestamp?: string, maxCount?: number, filedOnly?: boolean, pilotId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (customerGuid === null || customerGuid === undefined) {
            throw new Error('Required parameter customerGuid was null or undefined when calling getFlightsForUser.');
        }


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (includeDeleted !== undefined && includeDeleted !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeDeleted, 'include-deleted');
        }
        if (startTimestamp !== undefined && startTimestamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startTimestamp, 'start-timestamp');
        }
        if (endTimestamp !== undefined && endTimestamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endTimestamp, 'end-timestamp');
        }
        if (maxCount !== undefined && maxCount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>maxCount, 'max-count');
        }
        if (filedOnly !== undefined && filedOnly !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filedOnly, 'filed-only');
        }
        if (pilotId !== undefined && pilotId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pilotId, 'pilot-id');
        }

        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/flights/user/${this.configuration.encodeParam({name: "customerGuid", value: customerGuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Array<FlightV2>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generates and returns the briefing report for a specified flight id. Allowed Type values are STANDARD, ABBREVIATED, or OUTLOOK.
     * @param flightId 
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPdfBriefingReport(flightId: string, type?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<string>;
    public getPdfBriefingReport(flightId: string, type?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<string>>;
    public getPdfBriefingReport(flightId: string, type?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<string>>;
    public getPdfBriefingReport(flightId: string, type?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/pdf' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (flightId === null || flightId === undefined) {
            throw new Error('Required parameter flightId was null or undefined when calling getPdfBriefingReport.');
        }


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (type !== undefined && type !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>type, 'type');
        }

        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/pdf',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/flights/${this.configuration.encodeParam({name: "flightId", value: flightId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/pdf-briefing-report`;
        return this.httpClient.request<string>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Attempts to modify a previously filed flight plan. The entire new Flight object must be supplied in the request
     * @param flightId 
     * @param flightChangeRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modifyFlightPlanFiling(flightId: string, flightChangeRequest: FlightChangeRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<FlightV2>;
    public modifyFlightPlanFiling(flightId: string, flightChangeRequest: FlightChangeRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<FlightV2>>;
    public modifyFlightPlanFiling(flightId: string, flightChangeRequest: FlightChangeRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<FlightV2>>;
    public modifyFlightPlanFiling(flightId: string, flightChangeRequest: FlightChangeRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (flightId === null || flightId === undefined) {
            throw new Error('Required parameter flightId was null or undefined when calling modifyFlightPlanFiling.');
        }
        if (flightChangeRequest === null || flightChangeRequest === undefined) {
            throw new Error('Required parameter flightChangeRequest was null or undefined when calling modifyFlightPlanFiling.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/flights/${this.configuration.encodeParam({name: "flightId", value: flightId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/file/modify`;
        return this.httpClient.request<FlightV2>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: flightChangeRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Searches for flights matching specified parameters.
     * @param flightSearchParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchFlight(flightSearchParams: FlightSearchParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<Array<FlightV2>>;
    public searchFlight(flightSearchParams: FlightSearchParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<Array<FlightV2>>>;
    public searchFlight(flightSearchParams: FlightSearchParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<Array<FlightV2>>>;
    public searchFlight(flightSearchParams: FlightSearchParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (flightSearchParams === null || flightSearchParams === undefined) {
            throw new Error('Required parameter flightSearchParams was null or undefined when calling searchFlight.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/flights/search`;
        return this.httpClient.request<Array<FlightV2>>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: flightSearchParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Attempts to update an existing flight. The entire new Flight object must be supplied in the request
     * @param flightId 
     * @param flightChangeRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFlight(flightId: string, flightChangeRequest: FlightChangeRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<FlightV2>;
    public updateFlight(flightId: string, flightChangeRequest: FlightChangeRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<FlightV2>>;
    public updateFlight(flightId: string, flightChangeRequest: FlightChangeRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<FlightV2>>;
    public updateFlight(flightId: string, flightChangeRequest: FlightChangeRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (flightId === null || flightId === undefined) {
            throw new Error('Required parameter flightId was null or undefined when calling updateFlight.');
        }
        if (flightChangeRequest === null || flightChangeRequest === undefined) {
            throw new Error('Required parameter flightChangeRequest was null or undefined when calling updateFlight.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/flights/${this.configuration.encodeParam({name: "flightId", value: flightId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<FlightV2>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: flightChangeRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
