<div class="waypoint">
  @if (!waypointSelected) {
    <button
      flyButton
      class="open-search-btn"
      [class.hidden]="showSearch"
      (click)="onOpenSearch()"
      title="Open search"
      aria-label="Open search"
      [options]="{ style: 'map', size: 'map-icononly' }">
      <fly-icon [icon]="Icons.Search"></fly-icon>
    </button>
    <div
      class="search"
      [class.hidden]="!showSearch">
      <form [formGroup]="formGroup">
        <div class="search-input">
          <fly-input formControlName="searchControl"
            [autocomplete]="false"
            (focusin)="showContent()"
            [label]="label"
            [errorStrings]="errorMap"
            name="search"
            placeholder="Search">
            <fly-icon [icon]="Icons.Search"
              slot="icon-left"></fly-icon>
            @if (currentSearchState !== State.NoSelection) {
              <fly-icon class="cancel-icon"
                [icon]="Icons.Cancel"
                (click)="onCancelSearch()"
                slot="icon-right"></fly-icon>
            }
          </fly-input>
        </div>
      </form>
    </div>
    @if (showSearchContent) {
      <div class="tab-item results">
        @switch (currentSearchState) {
          @case (State.Loaded) {
            <div class="loaded-results">
              @for (airport of airports(); track $index) {
                <pilot-airport-search-card
                  (click)="onSelectAirport(airport)"
                  [airport]="airport"
                  [ngStyle]="{ 'order': displayOrder[$index] }">
                </pilot-airport-search-card>
              }

              @for (waypoint of waypoints(); track $index) {
                <pilot-search-card
                  (click)="onSelectWaypoint(waypoint)"
                  [waypoint]="waypoint"
                  [ngStyle]="{ 'order': displayOrder[airports().length + $index] }">
                </pilot-search-card>
              }
            </div>
          }

          @case (State.Loading) {
            <fly-loading-spinner message="Retrieving data...">
            </fly-loading-spinner>
          }

          @case (State.NoDataAvailable) {
            <div>No results found.</div>
          }

          @case (State.NoSelection) {
            <div class="explanation">
              <span class="body-1-semibold">Search for things like...</span>
              <ul>
                <li>
                  <span class="explain-left body-2-semibold">KFCM</span>
                  <span class="explain-right body-2"> - Find waypoints by identifier</span>
                </li>
                <li>
                  <span class="explain-left body-2-semibold">Minneapolis</span>
                  <span class="explain-right body-2"> - Find by city name</span>
                </li>
                <li>
                  <span class="body-explain-left body-2-semibold">Flying Cloud</span>
                  <span class="explain-right body-2"> - Find by facility name</span>
                </li>
              </ul>
            </div>
          }
        }
      </div>
    }
  } @else if (waypointChosen.waypoint != null) {
    <div class="wp-label">
      <span>{{ label }}</span>
      <fly-icon class="required-icon"
        [icon]="Icons.Required"></fly-icon>
    </div>
    <div class="wrap">
      <div>
        <fly-icon [icon]="icon"></fly-icon>
      </div>
      <div class="waypoint-data">
        <div>{{ waypointChosen.waypoint.identifier }}</div>
        <!-- TODO: Add titlecase -->
        <div class="waypoint-name">{{ displayedSubName }}</div>
      </div>
      <button flyButton
        class="fly-modal-close-button"
        [options]="{
          style: 'ghost',
          size: 'medium'
        }"
        (click)="onDeleteWaypoint()">
        <fly-icon [icon]="Icons.Close"></fly-icon>
      </button>
    </div>
  } @else if (waypointChosen.airport != null) {
    <div class="wp-label">
      <span>{{ label }}</span>
      <fly-icon class="required-icon"
        [icon]="Icons.Required"></fly-icon>
    </div>
    <div class="wrap">
      @if (waypointChosen.airport.icon == null || waypointChosen.airport.icon.length === 0) {
        <fly-icon [icon]="FlightIcons.Airports.ToweredServices"></fly-icon>
      } @else {
        <div>
          <img alt="runway diagram"
            src="data:image/svg+xml;base64, {{waypointChosen.airport.icon}}">
        </div>
      }
      <div class="waypoint-data">
        <div>{{ waypointChosen.airport.icao }}</div>
        <div class="waypoint-name">{{ waypointChosen.airport.name | flyExtendedTitleCase }}</div>
      </div>
      <button flyButton
        class="fly-modal-close-button"
        [options]="{
          style: 'ghost',
          size: 'medium'
        }"
        (click)="onDeleteWaypoint()">
        <fly-icon [icon]="Icons.Close"></fly-icon>
      </button>
    </div>
  }
</div>
