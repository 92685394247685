// index.ts
import { polygon } from "@turf/helpers";
function bboxPolygon(bbox, options = {}) {
  const west = Number(bbox[0]);
  const south = Number(bbox[1]);
  const east = Number(bbox[2]);
  const north = Number(bbox[3]);
  if (bbox.length === 6) {
    throw new Error("@turf/bbox-polygon does not support BBox with 6 positions");
  }
  const lowLeft = [west, south];
  const topLeft = [west, north];
  const topRight = [east, north];
  const lowRight = [east, south];
  return polygon([[lowLeft, lowRight, topRight, topLeft, lowLeft]], options.properties, {
    bbox,
    id: options.id
  });
}
var turf_bbox_polygon_default = bboxPolygon;
export { bboxPolygon, turf_bbox_polygon_default as default };
