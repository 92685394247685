import { InjectionToken, type ValueProvider, type WritableSignal, signal } from '@angular/core';
import { isEqual } from 'lodash';
import { type AirportContent, AirportContentPath } from '../types/airport-content.types';

export const AIRPORT_CONTENT_SIGNAL = new InjectionToken<WritableSignal<AirportContent>>('AIRPORT_CONTENT_SIGNAL');

export const provideAirportContentSignal = (): ValueProvider => ({
  provide: AIRPORT_CONTENT_SIGNAL,
  useValue: signal({ path: AirportContentPath.MetarTaf }, { equal: isEqual })
});
