// index.ts
import { convex } from "@turf/convex";
import { centroid } from "@turf/centroid";
import { point } from "@turf/helpers";
import { getType, getCoord } from "@turf/invariant";
import { coordEach } from "@turf/meta";
function centerOfMass(geojson, options = {}) {
  switch (getType(geojson)) {
    case "Point":
      return point(getCoord(geojson), options.properties);
    case "Polygon":
      var coords = [];
      coordEach(geojson, function (coord) {
        coords.push(coord);
      });
      var centre = centroid(geojson, {
        properties: options.properties
      });
      var translation = centre.geometry.coordinates;
      var sx = 0;
      var sy = 0;
      var sArea = 0;
      var i, pi, pj, xi, xj, yi, yj, a;
      var neutralizedPoints = coords.map(function (point2) {
        return [point2[0] - translation[0], point2[1] - translation[1]];
      });
      for (i = 0; i < coords.length - 1; i++) {
        pi = neutralizedPoints[i];
        xi = pi[0];
        yi = pi[1];
        pj = neutralizedPoints[i + 1];
        xj = pj[0];
        yj = pj[1];
        a = xi * yj - xj * yi;
        sArea += a;
        sx += (xi + xj) * a;
        sy += (yi + yj) * a;
      }
      if (sArea === 0) {
        return centre;
      } else {
        var area = sArea * 0.5;
        var areaFactor = 1 / (6 * area);
        return point([translation[0] + areaFactor * sx, translation[1] + areaFactor * sy], options.properties);
      }
    default:
      var hull = convex(geojson);
      if (hull) return centerOfMass(hull, {
        properties: options.properties
      });else return centroid(geojson, {
        properties: options.properties
      });
  }
}
var turf_center_of_mass_default = centerOfMass;
export { centerOfMass, turf_center_of_mass_default as default };
