import { InjectionToken, type ValueProvider, type WritableSignal, signal } from '@angular/core';

export const ProcedureKind = {
  Departure: 'Departure',
  Arrival: 'Arrival',
} as const;
// eslint-disable-next-line no-redeclare
export type ProcedureKind = (typeof ProcedureKind)[keyof typeof ProcedureKind];

export const ProcedureOverlayMode = {
  ...ProcedureKind,
  Default: 'Default',
} as const;
// eslint-disable-next-line no-redeclare
export type ProcedureOverlayMode = (typeof ProcedureOverlayMode)[keyof typeof ProcedureOverlayMode];

export const PROCEDURE_OVERLAY_MODE = new InjectionToken<WritableSignal<ProcedureOverlayMode>>(
  'PROCEDURE_OVERLAY_MODE',
);

export const provideProcedureMode = (): ValueProvider => ({
  provide: PROCEDURE_OVERLAY_MODE,
  useValue: signal<ProcedureOverlayMode>(ProcedureOverlayMode.Default),
});
