/**
 * FlightOrchestratorService
 * flightOrchestratorService
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-ProdExp-Cloud-Connectivity@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { GetWaypointsResponse } from '../model/get-waypoints-response.model';
// @ts-ignore
import { GpWaypoint } from '../model/gp-waypoint.model';
// @ts-ignore
import { IsSyncRequiredResponse } from '../model/is-sync-required-response.model';
// @ts-ignore
import { SyncWaypointsRequest } from '../model/sync-waypoints-request.model';
// @ts-ignore
import { SyncWaypointsResponse } from '../model/sync-waypoints-response.model';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { FlightOrchestratorServiceConfiguration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class WaypointSyncControllerService {

    protected basePath = 'https://avcloud-api-test.garmin.com/flightOrchestratorService';
    public defaultHeaders = new HttpHeaders();
    public configuration = new FlightOrchestratorServiceConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: FlightOrchestratorServiceConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Retrieve the list of all this user\&#39;s waypoints
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _1(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<GetWaypointsResponse>;
    public _1(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<GetWaypointsResponse>>;
    public _1(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<GetWaypointsResponse>>;
    public _1(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/sync/gpsync/waypoints`;
        return this.httpClient.request<GetWaypointsResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a new waypoint
     * @param gpWaypoint 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _1_1(gpWaypoint: GpWaypoint, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<GpWaypoint>;
    public _1_1(gpWaypoint: GpWaypoint, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<GpWaypoint>>;
    public _1_1(gpWaypoint: GpWaypoint, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<GpWaypoint>>;
    public _1_1(gpWaypoint: GpWaypoint, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (gpWaypoint === null || gpWaypoint === undefined) {
            throw new Error('Required parameter gpWaypoint was null or undefined when calling _1_1.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/sync/gpsync/waypoints`;
        return this.httpClient.request<GpWaypoint>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: gpWaypoint,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Archives existing user waypoint data by id
     * @param waypointId 
     * @param updatedDataVer 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public archiveWaypointById(waypointId: number, updatedDataVer?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any>;
    public archiveWaypointById(waypointId: number, updatedDataVer?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<any>>;
    public archiveWaypointById(waypointId: number, updatedDataVer?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<any>>;
    public archiveWaypointById(waypointId: number, updatedDataVer?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (waypointId === null || waypointId === undefined) {
            throw new Error('Required parameter waypointId was null or undefined when calling archiveWaypointById.');
        }


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (updatedDataVer !== undefined && updatedDataVer !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>updatedDataVer, 'updated-data-ver');
        }

        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/sync/gpsync/waypoints/${this.configuration.encodeParam({name: "waypointId", value: waypointId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve waypoint data in the requested schema version by waypoint id
     * @param waypointId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWaypointById(waypointId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<GpWaypoint>;
    public getWaypointById(waypointId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<GpWaypoint>>;
    public getWaypointById(waypointId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<GpWaypoint>>;
    public getWaypointById(waypointId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (waypointId === null || waypointId === undefined) {
            throw new Error('Required parameter waypointId was null or undefined when calling getWaypointById.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/sync/gpsync/waypoints/${this.configuration.encodeParam({name: "waypointId", value: waypointId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})}`;
        return this.httpClient.request<GpWaypoint>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check if waypoints sync is needed
     * @param dataVer 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isWaypointsSyncRequired(dataVer: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<IsSyncRequiredResponse>;
    public isWaypointsSyncRequired(dataVer: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<IsSyncRequiredResponse>>;
    public isWaypointsSyncRequired(dataVer: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<IsSyncRequiredResponse>>;
    public isWaypointsSyncRequired(dataVer: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (dataVer === null || dataVer === undefined) {
            throw new Error('Required parameter dataVer was null or undefined when calling isWaypointsSyncRequired.');
        }


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (dataVer !== undefined && dataVer !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dataVer, 'data-ver');
        }

        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/sync/gpsync/waypoints/is-sync-required`;
        return this.httpClient.request<IsSyncRequiredResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sync (aka merge) the request body into the users current waypoint data
     * @param syncWaypointsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public syncWaypoints(syncWaypointsRequest: SyncWaypointsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<SyncWaypointsResponse>;
    public syncWaypoints(syncWaypointsRequest: SyncWaypointsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<SyncWaypointsResponse>>;
    public syncWaypoints(syncWaypointsRequest: SyncWaypointsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<SyncWaypointsResponse>>;
    public syncWaypoints(syncWaypointsRequest: SyncWaypointsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (syncWaypointsRequest === null || syncWaypointsRequest === undefined) {
            throw new Error('Required parameter syncWaypointsRequest was null or undefined when calling syncWaypoints.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/sync/gpsync/waypoints/sync`;
        return this.httpClient.request<SyncWaypointsResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: syncWaypointsRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modify existing user waypoint data by id
     * @param waypointId 
     * @param gpWaypoint 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWaypointById(waypointId: number, gpWaypoint: GpWaypoint, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any>;
    public updateWaypointById(waypointId: number, gpWaypoint: GpWaypoint, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<any>>;
    public updateWaypointById(waypointId: number, gpWaypoint: GpWaypoint, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<any>>;
    public updateWaypointById(waypointId: number, gpWaypoint: GpWaypoint, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (waypointId === null || waypointId === undefined) {
            throw new Error('Required parameter waypointId was null or undefined when calling updateWaypointById.');
        }
        if (gpWaypoint === null || gpWaypoint === undefined) {
            throw new Error('Required parameter gpWaypoint was null or undefined when calling updateWaypointById.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/sync/gpsync/waypoints/${this.configuration.encodeParam({name: "waypointId", value: waypointId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})}`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: gpWaypoint,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
