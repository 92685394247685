/**
 * FlightOrchestratorService
 * flightOrchestratorService
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-ProdExp-Cloud-Connectivity@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FilingStatusInfo } from './filing-status-info.model';


/**
 * Filing information for this flight. Can be left null if there are no changes to FilingInfo.
 */
export interface FilingInfo { 
    filingStatusInfo?: FilingStatusInfo;
    /**
     * (enum) The ICAO flight type (Expected to be null if this is a Canadian flight)
     */
    flightType?: FilingInfoFlightType;
    /**
     * (enum) The Canadian flight type (Expected to be null if this is not a Canadian flight)
     */
    canadianFlightType?: FilingInfoCanadianFlightType;
    /**
     * The Canadian certificate number (Expected to be null if this is not a Canadian flight)
     */
    canadianCertificateNumber?: string;
    /**
     * (enum) The flight rules
     */
    flightRules?: FilingInfoFlightRules;
    /**
     * DEPRECATED (Use Route DepartureDateTime instead). Date of the flight\'s departure, in UTC
     */
    dateOfFlight?: string;
    /**
     * Name of persons/company to be notified if Search-And-Rescue action is initiated, intended to be persons responsible for the aircraft who are not on the flight itself.
     */
    sarNotificationName?: string;
    /**
     * Phone number of persons/company to be notified if Search-And-Rescue action is initiated, intended to be persons responsible for the aircraft who are not on the flight itself.
     */
    sarNotificationPhone?: string;
    /**
     * Address of persons/company to be notified if Search-And-Rescue action is initiated, intended to be persons responsible for the aircraft who are not on the flight itself.
     */
    sarNotificationAddress?: string;
    /**
     * Number of minutes after ETA in which a search and rescue operation will be issued. Minimum 1min, maximum 24 hours (1440 minutes).
     */
    sarTimeMinutes?: number;
    /**
     * This specifies the contact information for where the pilot will deliver their arrival report, when the pilot will close their flight plan/itinerary with a unit other than the destination unit or by another method than radio.
     */
    arrivalContact?: string;
    /**
     * General remarks for the flight plan
     */
    remarks?: string;
    /**
     * The SFRA flight type, if the flight is a SFRA flight
     */
    sfraFlightType?: FilingInfoSfraFlightType;
    item18SpecialStatus?: string;
    item18EetOverride?: string;
    item18EnrouteDelay?: string;
    item18EnrouteAlternate?: string;
    item18TakeoffAlternate?: string;
    item18ReclearanceInFlight?: string;
    item18ReplacementFlightPlan?: string;
}
export enum FilingInfoFlightType {
    GENERAL = 'GENERAL',
    MILITARY = 'MILITARY',
    NON_SCHEDULED = 'NON_SCHEDULED',
    SCHEDULED = 'SCHEDULED',
    OTHER = 'OTHER'
};
export enum FilingInfoCanadianFlightType {
    CVFR = 'CVFR',
    DVFR_FP = 'DVFR_FP',
    DVFR_FI_OR_MILITARY = 'DVFR_FI_OR_MILITARY',
    FLIGHT_ITINERARY_OR_SCHEDULED = 'FLIGHT_ITINERARY_OR_SCHEDULED',
    GENERAL_AVIATION = 'GENERAL_AVIATION',
    NON_SCHEDULED = 'NON_SCHEDULED',
    OTHER = 'OTHER'
};
export enum FilingInfoFlightRules {
    VFR = 'VFR',
    IFR = 'IFR',
    YFR = 'YFR',
    ZFR = 'ZFR',
    SFRA = 'SFRA'
};
export enum FilingInfoSfraFlightType {
    DEPARTURE = 'DEPARTURE',
    ENTRY = 'ENTRY',
    TRANSIT = 'TRANSIT',
    WITHIN = 'WITHIN'
};



