// index.ts
import { convertLength, degreesToRadians, earthRadius, point } from "@turf/helpers";
import { getCoord } from "@turf/invariant";
function rhumbDestination(origin, distance, bearing, options = {}) {
  const wasNegativeDistance = distance < 0;
  let distanceInMeters = convertLength(Math.abs(distance), options.units, "meters");
  if (wasNegativeDistance) distanceInMeters = -Math.abs(distanceInMeters);
  const coords = getCoord(origin);
  const destination = calculateRhumbDestination(coords, distanceInMeters, bearing);
  destination[0] += destination[0] - coords[0] > 180 ? -360 : coords[0] - destination[0] > 180 ? 360 : 0;
  return point(destination, options.properties);
}
function calculateRhumbDestination(origin, distance, bearing, radius) {
  radius = radius === void 0 ? earthRadius : Number(radius);
  const delta = distance / radius;
  const lambda1 = origin[0] * Math.PI / 180;
  const phi1 = degreesToRadians(origin[1]);
  const theta = degreesToRadians(bearing);
  const DeltaPhi = delta * Math.cos(theta);
  let phi2 = phi1 + DeltaPhi;
  if (Math.abs(phi2) > Math.PI / 2) {
    phi2 = phi2 > 0 ? Math.PI - phi2 : -Math.PI - phi2;
  }
  const DeltaPsi = Math.log(Math.tan(phi2 / 2 + Math.PI / 4) / Math.tan(phi1 / 2 + Math.PI / 4));
  const q = Math.abs(DeltaPsi) > 1e-11 ? DeltaPhi / DeltaPsi : Math.cos(phi1);
  const DeltaLambda = delta * Math.sin(theta) / q;
  const lambda2 = lambda1 + DeltaLambda;
  return [(lambda2 * 180 / Math.PI + 540) % 360 - 180, phi2 * 180 / Math.PI];
}
var turf_rhumb_destination_default = rhumbDestination;
export { turf_rhumb_destination_default as default, rhumbDestination };
