/**
 * FlightOrchestratorService
 * flightOrchestratorService
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-ProdExp-Cloud-Connectivity@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { GetUserRoutesResponse } from '../model/get-user-routes-response.model';
// @ts-ignore
import { GpUserRoute } from '../model/gp-user-route.model';
// @ts-ignore
import { IsSyncRequiredResponse } from '../model/is-sync-required-response.model';
// @ts-ignore
import { SyncUserRoutesRequest } from '../model/sync-user-routes-request.model';
// @ts-ignore
import { SyncUserRoutesResponse } from '../model/sync-user-routes-response.model';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { FlightOrchestratorServiceConfiguration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class UserRouteSyncControllerService {

    protected basePath = 'https://avcloud-api-test.garmin.com/flightOrchestratorService';
    public defaultHeaders = new HttpHeaders();
    public configuration = new FlightOrchestratorServiceConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: FlightOrchestratorServiceConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Add a new route
     * @param gpUserRoute 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addRoute(gpUserRoute: GpUserRoute, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<GpUserRoute>;
    public addRoute(gpUserRoute: GpUserRoute, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<GpUserRoute>>;
    public addRoute(gpUserRoute: GpUserRoute, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<GpUserRoute>>;
    public addRoute(gpUserRoute: GpUserRoute, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (gpUserRoute === null || gpUserRoute === undefined) {
            throw new Error('Required parameter gpUserRoute was null or undefined when calling addRoute.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/sync/gpsync/routes`;
        return this.httpClient.request<GpUserRoute>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: gpUserRoute,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Archives existing user route data by id
     * @param routeId 
     * @param updatedDataVer 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public archiveRouteById(routeId: number, updatedDataVer?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any>;
    public archiveRouteById(routeId: number, updatedDataVer?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<any>>;
    public archiveRouteById(routeId: number, updatedDataVer?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<any>>;
    public archiveRouteById(routeId: number, updatedDataVer?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (routeId === null || routeId === undefined) {
            throw new Error('Required parameter routeId was null or undefined when calling archiveRouteById.');
        }


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (updatedDataVer !== undefined && updatedDataVer !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>updatedDataVer, 'updated-data-ver');
        }

        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/sync/gpsync/routes/${this.configuration.encodeParam({name: "routeId", value: routeId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve route data in the requested schema version by route id
     * @param routeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRouteById(routeId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<GpUserRoute>;
    public getRouteById(routeId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<GpUserRoute>>;
    public getRouteById(routeId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<GpUserRoute>>;
    public getRouteById(routeId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (routeId === null || routeId === undefined) {
            throw new Error('Required parameter routeId was null or undefined when calling getRouteById.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/sync/gpsync/routes/${this.configuration.encodeParam({name: "routeId", value: routeId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})}`;
        return this.httpClient.request<GpUserRoute>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve the list of all this user\&#39;s routes
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRoutes(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<GetUserRoutesResponse>;
    public getRoutes(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<GetUserRoutesResponse>>;
    public getRoutes(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<GetUserRoutesResponse>>;
    public getRoutes(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/sync/gpsync/routes`;
        return this.httpClient.request<GetUserRoutesResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check if routes sync is needed
     * @param dataVer 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isRoutesSyncRequired(dataVer: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<IsSyncRequiredResponse>;
    public isRoutesSyncRequired(dataVer: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<IsSyncRequiredResponse>>;
    public isRoutesSyncRequired(dataVer: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<IsSyncRequiredResponse>>;
    public isRoutesSyncRequired(dataVer: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (dataVer === null || dataVer === undefined) {
            throw new Error('Required parameter dataVer was null or undefined when calling isRoutesSyncRequired.');
        }


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (dataVer !== undefined && dataVer !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dataVer, 'data-ver');
        }

        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/sync/gpsync/routes/is-sync-required`;
        return this.httpClient.request<IsSyncRequiredResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sync (aka merge) the request body into the users current route data
     * @param syncUserRoutesRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public syncRoutes(syncUserRoutesRequest: SyncUserRoutesRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<SyncUserRoutesResponse>;
    public syncRoutes(syncUserRoutesRequest: SyncUserRoutesRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<SyncUserRoutesResponse>>;
    public syncRoutes(syncUserRoutesRequest: SyncUserRoutesRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<SyncUserRoutesResponse>>;
    public syncRoutes(syncUserRoutesRequest: SyncUserRoutesRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (syncUserRoutesRequest === null || syncUserRoutesRequest === undefined) {
            throw new Error('Required parameter syncUserRoutesRequest was null or undefined when calling syncRoutes.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/sync/gpsync/routes/sync`;
        return this.httpClient.request<SyncUserRoutesResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: syncUserRoutesRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modify existing user route data by id
     * @param routeId 
     * @param gpUserRoute 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateRouteById(routeId: number, gpUserRoute: GpUserRoute, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any>;
    public updateRouteById(routeId: number, gpUserRoute: GpUserRoute, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<any>>;
    public updateRouteById(routeId: number, gpUserRoute: GpUserRoute, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<any>>;
    public updateRouteById(routeId: number, gpUserRoute: GpUserRoute, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (routeId === null || routeId === undefined) {
            throw new Error('Required parameter routeId was null or undefined when calling updateRouteById.');
        }
        if (gpUserRoute === null || gpUserRoute === undefined) {
            throw new Error('Required parameter gpUserRoute was null or undefined when calling updateRouteById.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/sync/gpsync/routes/${this.configuration.encodeParam({name: "routeId", value: routeId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})}`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: gpUserRoute,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
