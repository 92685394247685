import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environment';
import { AuthService, LocalStorageKey, LocalStorageService, OAuthTokenResponse } from '@garmin-avcloud/avcloud-web-utils';
import { SettingsCrewPilotModel } from '@shared/models/settings/crew/settings-crew-pilot.model';
import { SettingsWaypointsWaypointModel } from '@shared/models/settings/waypoints/settings-waypoints-waypoint.model';
import { map, Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlightOrchestratorUserSettingsService {

  readonly SERVICE_URL: string = `${environment.garmin.aviation.servicesHost}/flightOrchestratorService/v1`;

  private readonly authService = inject(AuthService);
  private readonly http = inject(HttpClient);
  private readonly localStorageService = inject(LocalStorageService);

  // ** CREW **

  getCrew(): Observable<SettingsCrewPilotModel[] | null> {
    return this.getCustomerId()
      .pipe(
        switchMap((uuid) => uuid == null
          ? of(null)
          : this.http.get<SettingsCrewPilotModel[]>(`${this.SERVICE_URL}/users/${uuid}/pilots`)
        ));
  }

  deleteCrew(crewIds: string[]): Observable<null> {
    return this.getCustomerId()
    .pipe(
      switchMap((uuid) => uuid == null
        ? of(null)
        : this.http.delete<null>(`${this.SERVICE_URL}/users/${uuid}/pilots?pilot-ids=${crewIds}`)
      ));
  }

  // ** WAYPOINTS **

  getWaypoints(): Observable<SettingsWaypointsWaypointModel[] | null> {
    return this.getCustomerId()
      .pipe(
        switchMap((uuid) => uuid == null
          ? of(null)
          : this.http.get<SettingsWaypointsWaypointModel[]>(`${this.SERVICE_URL}/users/${uuid}/waypoints`)
        ));
  }

  getWaypointsByName(name: string): Observable<SettingsWaypointsWaypointModel[] | null> {
    return this.getCustomerId()
    .pipe(
      switchMap((uuid) => uuid == null
        ? of(null)
        : this.http.get<SettingsWaypointsWaypointModel[]>(
          `${this.SERVICE_URL}/users/${uuid}/waypoints`,
          { params: { 'name-contains': name } }
        )
      ));
  }

  postWaypoint(waypoints: SettingsWaypointsWaypointModel): Observable<SettingsWaypointsWaypointModel | null> {
    return this.getCustomerId()
    .pipe(
      switchMap((uuid) => uuid == null
        ? of(null)
        : this.http.post<SettingsWaypointsWaypointModel>(`${this.SERVICE_URL}/users/${uuid}/waypoints`, waypoints)
      ));
  }

  putWaypoint(waypoints: SettingsWaypointsWaypointModel): Observable<SettingsWaypointsWaypointModel | null> {
    return this.getCustomerId()
    .pipe(
      switchMap((uuid) => uuid == null
        ? of(null)
        : this.http.put<SettingsWaypointsWaypointModel>(`${this.SERVICE_URL}/users/${uuid}/waypoints/${waypoints.id}`, waypoints)
      ));
  }

  deleteWaypoints(waypointIds: string[]): Observable<null> {
    return this.getCustomerId()
    .pipe(
      switchMap((uuid) => uuid == null
        ? of(null)
        : this.http.delete<null>(`${this.SERVICE_URL}/users/${uuid}/waypoints?waypoint-ids=${waypointIds}`)
      ));
  }

  /**
   * Returns the customer's UUID if authenticated and present, otherwise null.
   */
  private getCustomerId(): Observable<string | null> {
    return this.authService.isAuthenticated().pipe(
      map((isAuthenticated) => isAuthenticated
        ? this.localStorageService.get<OAuthTokenResponse>(LocalStorageKey.OAuthTokenResponse)?.customerId ?? null
        : null
      )
    );
  }
}
