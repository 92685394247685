// index.ts
import { degreesToRadians, radiansToDegrees } from "@turf/helpers";
import { getCoord } from "@turf/invariant";
function rhumbBearing(start, end, options = {}) {
  let bear360;
  if (options.final) {
    bear360 = calculateRhumbBearing(getCoord(end), getCoord(start));
  } else {
    bear360 = calculateRhumbBearing(getCoord(start), getCoord(end));
  }
  const bear180 = bear360 > 180 ? -(360 - bear360) : bear360;
  return bear180;
}
function calculateRhumbBearing(from, to) {
  const phi1 = degreesToRadians(from[1]);
  const phi2 = degreesToRadians(to[1]);
  let deltaLambda = degreesToRadians(to[0] - from[0]);
  if (deltaLambda > Math.PI) {
    deltaLambda -= 2 * Math.PI;
  }
  if (deltaLambda < -Math.PI) {
    deltaLambda += 2 * Math.PI;
  }
  const deltaPsi = Math.log(Math.tan(phi2 / 2 + Math.PI / 4) / Math.tan(phi1 / 2 + Math.PI / 4));
  const theta = Math.atan2(deltaLambda, deltaPsi);
  return (radiansToDegrees(theta) + 360) % 360;
}
var turf_rhumb_bearing_default = rhumbBearing;
export { turf_rhumb_bearing_default as default, rhumbBearing };
