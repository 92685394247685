import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, effect, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { environment } from '@environment';
import {
  AirmetSigmetInfo,
  AirspaceInfo,
  AirwayInfo,
  AvcMapsDisplayService,
  MapPoint,
  InternetTrafficInfo,
  NotamInfo,
  ObstacleInfo,
  PirepInfo,
  StormCellInfo,
  TfrInfo,
} from '@garmin-avcloud/avc-maps-display';
import { AuthService } from '@garmin-avcloud/avcloud-web-utils';
import { RouteComputedLeg } from '@generated/flight-orchestrator-service';
import { FlightRouteControllerService, RouteComputedLegLocationType, UnifiedTokenRequest, UnifiedTokenRequestDesiredLocationTypes, UnifiedTokenResponse } from '@generated/flight-route-service';
import { State } from '@shared/enums/loading-state.enum';
import _ from 'lodash';
import { Observable, Subscription, forkJoin, tap } from 'rxjs';

export enum ListFilter {
  None,
  All,
  AirmetSigmet,
  Airspace,
  Airway,
  InternetTraffic,
  NDB,
  NOTAM,
  Obstacle,
  PIREP,
  StormCell,
  TFR,
  VOR,
  WPT
}

export enum DetailsFilter {
  None,
  AirmetSigmet,
  NDB,
  NOTAM,
  PIREP,
  TFR,
  VOR,
  WPT
}

export enum InfoWindowPosition {
  TopRight,
  BottomRight
}

interface IdentifierSearch {
  identifier: string;
}

interface LocationSearch extends IdentifierSearch {
  point: MapPoint;
}

export interface InfoRequest {
  ndb: LocationSearch[];
  vor: LocationSearch[];
  wpt: LocationSearch[];
}

export interface Info {
  airmetSigmet: AirmetSigmetInfo[];
  airspace: AirspaceInfo[];
  airway: AirwayInfo[];
  internetTraffic: InternetTrafficInfo[];
  ndb: RouteComputedLeg[];
  notam: NotamInfo[];
  obstacle: ObstacleInfo[];
  pirep: PirepInfo[];
  stormCell: StormCellInfo[];
  tfr: TfrInfo[];
  vor: RouteComputedLeg[];
  wpt: RouteComputedLeg[];
}

@Injectable({
  providedIn: 'root'
})
export class InfoService implements OnDestroy {

  private readonly authService = inject(AuthService);
  private readonly flightRouteService = inject(FlightRouteControllerService);
  private readonly http = inject(HttpClient);
  private readonly mapService = inject(AvcMapsDisplayService);

  private readonly setInfo = signal<Info>(this.createBlankInfo());
  readonly info = this.setInfo.asReadonly();
  readonly state = signal(State.Loading);
  readonly position = signal(InfoWindowPosition.TopRight);
  readonly visible = signal(false);
  showBackButton = false;
  detailsIndex = 0;
  readonly showCards = signal(true);
  readonly listFilter = signal(ListFilter.All);
  readonly detailsFilter = signal(DetailsFilter.None);

  private readonly isAuthenticated = toSignal(this.authService.isAuthenticated(), { initialValue: false });
  private subs = new Subscription();

  constructor() {
    effect(() => this.processRadialMenu(), { allowSignalWrites: true });
    effect(() => this.processNewInfo(), { allowSignalWrites: true });
  }

  private getDetailsFilterForType(key: keyof Info): DetailsFilter {
    switch (key) {
      case 'airmetSigmet':
        return DetailsFilter.AirmetSigmet;
      case 'ndb':
        return DetailsFilter.NDB;
      case 'notam':
        return DetailsFilter.NOTAM;
      case 'pirep':
        return DetailsFilter.PIREP;
      case 'tfr':
        return DetailsFilter.TFR;
      case 'vor':
        return DetailsFilter.VOR;
      case 'wpt':
        return DetailsFilter.WPT;
      default:
        console.error('unknown details info type:', key);
        return DetailsFilter.None;
    }
  }

  private getListFilterForType(key: keyof Info): ListFilter {
    switch (key) {
      case 'airmetSigmet':
        return ListFilter.AirmetSigmet;
      case 'airway':
        return ListFilter.Airway;
      case 'airspace':
        return ListFilter.Airspace;
      case 'internetTraffic':
        return ListFilter.InternetTraffic;
      case 'ndb':
        return ListFilter.NDB;
      case 'notam':
        return ListFilter.NOTAM;
      case 'obstacle':
        return ListFilter.Obstacle;
      case 'stormCell':
        return ListFilter.StormCell;
      case 'pirep':
        return ListFilter.PIREP;
      case 'tfr':
        return ListFilter.TFR;
      case 'vor':
        return ListFilter.VOR;
      case 'wpt':
        return ListFilter.WPT;
      default:
        console.error('unknown list info type:', key);
        return ListFilter.None;
    }
  }

  private processNewInfo(): void {
    this.detailsIndex = 0;
    let numberOfTypesPresent = 0;
    let newListFilter = ListFilter.None;
    const newDetailsFilter = DetailsFilter.None;
    for (const [key, value] of Object.entries(this.info())) {
      if (_.isArray(value) && value.length > 0) {
        newListFilter = this.getListFilterForType(key as keyof Info);
        numberOfTypesPresent += 1;
      }
      if (numberOfTypesPresent > 1) {
        newListFilter = ListFilter.All;
        break;
      }
    }
    this.listFilter.set(newListFilter);
    this.detailsFilter.set(newDetailsFilter);
    this.showBackButton = false;
    this.showCards.set(true);
  }

  private processRadialMenu(): void {
    const networkRequests = this.processNetworkRequests();
    // If any network requests are pending, don't set the state to loaded
    this.processMapInfo(!networkRequests);
  }

  private processMapInfo(updateState: boolean): void {
    const airmetSigmets = this.mapService.search.airmetsSigmets();
    const airspaces = this.mapService.search.airspaces();
    const airways = this.mapService.search.airways();
    const internetTraffics = this.mapService.search.internetTraffic();
    const notams = this.mapService.search.notams();
    const obstacles = this.mapService.search.obstacles();
    const pireps = this.mapService.search.pireps();
    const stormCells = this.mapService.search.stormCells();
    const tfrs = this.mapService.search.tfrs();
    const infoExists = (
      airmetSigmets.length > 0
      || airspaces.length > 0
      || airways.length > 0
      || internetTraffics.length > 0
      || notams.length > 0
      || obstacles.length > 0
      || pireps.length > 0
      || stormCells.length > 0
      || tfrs.length > 0
    );
    if (infoExists) {
      this.provideInfo(
        {
          airmetSigmet: airmetSigmets,
          airspace: airspaces,
          airway: airways,
          internetTraffic: internetTraffics,
          notam: notams,
          obstacle: obstacles,
          pirep: pireps,
          stormCell: stormCells,
          tfr: tfrs
        },
        updateState,
        InfoWindowPosition.BottomRight
      );
    }
    if (infoExists && updateState) {
      this.visible.set(true);
    }
  }

  private processNetworkRequests(): boolean {
    let requestExists = false;
    const request: InfoRequest = {
      ndb: [],
      vor: [],
      wpt: []
    };
    for (const ndb of this.mapService.search.ndbs()) {
      request.ndb.push(ndb);
      requestExists = true;
    }
    for (const vor of this.mapService.search.vors()) {
      request.vor.push(vor);
      requestExists = true;
    }
    for (const wpt of this.mapService.search.wpts()) {
      request.wpt.push(wpt);
      requestExists = true;
    }
    if (requestExists) {
      this.position.set(InfoWindowPosition.BottomRight);
      this.state.set(State.Loading);
      this.visible.set(true);
      this.requestInfo(request, InfoWindowPosition.BottomRight).then(() => {
        this.state.set(State.Loaded);
      });
    }
    return requestExists;
  }

  ngOnDestroy(): void {
    this.reset(InfoWindowPosition.TopRight);
  }

  reset(position?: InfoWindowPosition): void {
    this.subs.unsubscribe();
    this.subs = new Subscription();
    this.setInfo.set(this.createBlankInfo());
    this.position.set(position ?? InfoWindowPosition.TopRight);
    this.visible.set(false);
    this.state.set(State.Loading);
    this.showBackButton = false;
  }

  requestInfo(requestedInfo: Partial<InfoRequest>, position: InfoWindowPosition): Promise<void> {
    return new Promise((resolve) => {
      const queryResults = this.createBlankInfo();
      const requests: Array<Observable<UnifiedTokenResponse>> = [];
      const pushRequest = (request: Observable<UnifiedTokenResponse>, target: RouteComputedLeg[]): void => {
        requests.push(
          request.pipe(
            tap((response) => {
              const value = response.resultsList?.at(0);
              if (value != null) {
                target.push(value);
              }
            })
          )
        );
      };
      for (const ndb of requestedInfo?.ndb ?? []) {
        pushRequest(this.queryNdb(ndb), queryResults.ndb);
      }
      for (const vor of requestedInfo?.vor ?? []) {
        pushRequest(this.queryVor(vor), queryResults.vor);
      }
      for (const wpt of requestedInfo?.wpt ?? []) {
        pushRequest(this.queryWpt(wpt), queryResults.wpt);
      }
      if (requests.length > 0) {
        this.subs.add(
          forkJoin(requests).subscribe(() => {
            this.provideInfo(queryResults, true, position);
            resolve();
          })
        );
      }
    });
  }

  provideWaypoint(waypoint: RouteComputedLeg, position: InfoWindowPosition): void {
    switch (waypoint.locationType) {
      case RouteComputedLegLocationType.NDB:
        this.provideInfo({ ndb: [waypoint] }, true, position);
        break;
      case RouteComputedLegLocationType.VOR:
        this.provideInfo({ vor: [waypoint] }, true, position);
        break;
      case RouteComputedLegLocationType.INTERSECTION:
        this.provideInfo({ wpt: [waypoint] }, true, position);
        break;
      default:
        console.error(`unsupported location type: ${waypoint.locationType}`);
        this.state.set(State.NoDataAvailable);
        break;
    }
  }

  provideInfo(newInfo: Partial<Info>, updateState: boolean, position: InfoWindowPosition): void {
    this.setInfo.update(() => {
      const info = this.createBlankInfo();
      _.merge(info, newInfo);
      this.sortInfo(info);
      return info;
    });
    this.position.set(position);
    if (updateState) {
      this.state.set(newInfo != null ? State.Loaded : State.NoDataAvailable);
    }
  }

  showWaypoint(waypoint: RouteComputedLeg, position: InfoWindowPosition): void {
    this.reset(position);
    this.provideWaypoint(waypoint, position);
    this.visible.set(true);
  }

  showInternetTraffic(aircraft: InternetTrafficInfo, position: InfoWindowPosition): void {
    this.reset(position);
    this.provideInfo({internetTraffic: [aircraft]}, true, position);
    this.visible.set(true);
  }

  private createBlankInfo(): Info {
    return {
      airmetSigmet: [],
      airspace: [],
      airway: [],
      internetTraffic: [],
      ndb: [],
      notam: [],
      obstacle: [],
      pirep: [],
      stormCell: [],
      tfr: [],
      vor: [],
      wpt: []
    };
  }

  private createLocationRequest(search: LocationSearch, type: UnifiedTokenRequestDesiredLocationTypes): UnifiedTokenRequest {
    return {
      name: search.identifier,
      lat: search.point.latitude,
      lon: search.point.longitude,
      exactSearch: true,
      desiredLocationTypes: [type]
    };
  }

  private queryNdb(search: LocationSearch): Observable<UnifiedTokenResponse> {
    const request = this.createLocationRequest(search, UnifiedTokenRequestDesiredLocationTypes.NDB);
    return this.queryFlightRouteService(request);
  }

  private queryVor(search: LocationSearch): Observable<UnifiedTokenResponse> {
    const request = this.createLocationRequest(search, UnifiedTokenRequestDesiredLocationTypes.VOR);
    return this.queryFlightRouteService(request);
  }

  private queryWpt(search: LocationSearch): Observable<UnifiedTokenResponse> {
    const request = this.createLocationRequest(search, UnifiedTokenRequestDesiredLocationTypes.INTERSECTION);
    return this.queryFlightRouteService(request);
  }

  private queryFlightRouteService(request: UnifiedTokenRequest): Observable<UnifiedTokenResponse> {
    if (this.isAuthenticated()) {
      return this.flightRouteService.unifiedTokenSearchPost(request);
    } else {
      return this.http.post<UnifiedTokenResponse>(
        `${environment.garmin.aviation.workerApiHost}/flights/token-search-unauthenticated`,
        request
      );
    }
  }

  private sortInfo(info: Info): void {
    info.airmetSigmet.sort((a, b) => {
      return (a.effectiveTime ?? 0) - (b.effectiveTime ?? 0);
    });
    info.airway.sort((a, b) => {
      // Airway IDs are a letter, some numbers, and maybe some more letters
      return a.name.localeCompare(b.name, undefined, { numeric: true });
    });
    info.internetTraffic.sort((a, b) => {
      if (a.id === undefined && b.id === undefined) return 0;
      if (a.id === undefined) return -1;
      if (b.id === undefined) return 1;
      return a.id.localeCompare(b.id, undefined, { numeric: true });
    });
    info.tfr.sort((a, b) => {
      // Sort by notam_ident and then start_time
      if (a.notam_ident !== undefined && b.notam_ident !== undefined) {
        if (a.notam_ident < b.notam_ident) {
          return -1;
        } else if (a.notam_ident > b.notam_ident) {
          return 1;
        } else if (a.start_time !== undefined && b.start_time !== undefined) {
          if (a.start_time < b.start_time) {
            return -1;
          } else if (a.start_time > b.start_time) {
            return 1;
          }
        }
      }
      return 0;
    });
  }
}
