import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { inject, DestroyRef, Injector, Component, Input, NgModule } from '@angular/core';
import { NgControl, FormControlName, FormGroupDirective, NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as i1 from '@garmin-avcloud/avcloud-ui-common/button';
import { FlyButtonModule } from '@garmin-avcloud/avcloud-ui-common/button';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
const _forTrack0 = ($index, $item) => $item.label;
const _c0 = (a0, a1) => ({
  style: a0,
  size: a1
});
function FlySegmentedControllerComponent_For_1_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 2);
  }
  if (rf & 2) {
    const $index_r2 = i0.ɵɵnextContext().$index;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("small", ctx_r2.size === "small")("visible", ctx_r2.activeValueIndex !== $index_r2 && ctx_r2.activeValueIndex !== $index_r2 + 1)("hidden", ctx_r2.hideDivider);
  }
}
function FlySegmentedControllerComponent_For_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 0);
    i0.ɵɵlistener("click", function FlySegmentedControllerComponent_For_1_Template_button_click_0_listener() {
      const $index_r2 = i0.ɵɵrestoreView(_r1).$index;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onClick($index_r2));
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(2, FlySegmentedControllerComponent_For_1_Conditional_2_Template, 1, 6, "div", 1);
  }
  if (rf & 2) {
    const option_r4 = ctx.$implicit;
    const $index_r2 = ctx.$index;
    const ɵ$index_1_r5 = ctx.$index;
    const ɵ$count_1_r6 = ctx.$count;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("small", ctx_r2.size === "small")("blue", ctx_r2.activeValueIndex === $index_r2)("neutral", ctx_r2.activeValueIndex !== $index_r2);
    i0.ɵɵproperty("options", i0.ɵɵpureFunction2(10, _c0, ctx_r2.buttonStyle, ctx_r2.size))("disabled", ctx_r2.disabled);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", option_r4.label, " ");
    i0.ɵɵadvance();
    i0.ɵɵconditional(!(ɵ$index_1_r5 === ɵ$count_1_r6 - 1) ? 2 : -1);
  }
}
class FlySegmentedControllerComponent {
  constructor() {
    this.size = 'medium';
    this.buttonStyle = 'primary';
    this.options = [];
    this.activeValueIndex = 0;
    this.hideDivider = false;
    this.disabled = false;
    this.touched = false;
    this.onChange = _value => {
      //
    };
    this.onTouched = () => {
      //
    };
    this.destroyRef = inject(DestroyRef);
    this.injector = inject(Injector);
  }
  ngOnInit() {
    // Grab injected form control instance.
    const ngControl = this.injector.get(NgControl);
    if (ngControl instanceof FormControlName) {
      this.controlIndex = this.injector.get(FormGroupDirective).getControl(ngControl);
    } else {
      this.controlIndex = ngControl.form;
    }
    this.controlIndex.setValue(this.activeValueIndex);
  }
  ngAfterViewInit() {
    this.controlIndex.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      this.activeValueIndex = value;
    });
  }
  writeValue(value) {
    this.value = value;
  }
  registerOnChange(onChange) {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched) {
    this.onTouched = onTouched;
  }
  onClick(value) {
    this.controlIndex.markAsTouched();
    if (value != this.controlIndex.value) this.controlIndex.markAsDirty();
    this.controlIndex.setValue(value);
  }
  static {
    this.ɵfac = function FlySegmentedControllerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FlySegmentedControllerComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FlySegmentedControllerComponent,
      selectors: [["fly-segmented-controller"]],
      inputs: {
        size: "size",
        buttonStyle: "buttonStyle",
        options: "options",
        activeValueIndex: "activeValueIndex",
        hideDivider: "hideDivider"
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: FlySegmentedControllerComponent
      }])],
      decls: 2,
      vars: 0,
      consts: [["flyButton", "", "type", "button", 1, "dt-button", 3, "click", "options", "disabled"], [1, "divider", 3, "small", "visible", "hidden"], [1, "divider"]],
      template: function FlySegmentedControllerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵrepeaterCreate(0, FlySegmentedControllerComponent_For_1_Template, 3, 13, null, null, _forTrack0);
        }
        if (rf & 2) {
          i0.ɵɵrepeater(ctx.options);
        }
      },
      dependencies: [i1.FlyButtonDirective],
      styles: [".dt-button[_ngcontent-%COMP%]{font-family:roboto;font-weight:600;font-size:.75rem;line-height:1.25rem}[_nghost-%COMP%]{display:flex;height:fit-content;flex-direction:row;justify-content:center;align-items:center;background-color:var(--interactive-background);border-radius:.25rem}.dt-button[_ngcontent-%COMP%]{flex:1}.divider[_ngcontent-%COMP%]{width:1px;height:1.125rem;background-color:var(--interactive-background);margin-left:.125rem;margin-right:.125rem}.divider.small[_ngcontent-%COMP%]{height:.875rem}.visible[_ngcontent-%COMP%]{background-color:var(--ui-data-entry)}.neutral[_ngcontent-%COMP%]{background-color:var(--interactive-background);color:var(--text-secondary)}.blue[_ngcontent-%COMP%]{border-right:none}.hidden[_ngcontent-%COMP%]{display:none}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlySegmentedControllerComponent, [{
    type: Component,
    args: [{
      selector: 'fly-segmented-controller',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: FlySegmentedControllerComponent
      }],
      template: "@for (option of options; track option.label) {\n  <button flyButton\n    class=\"dt-button\"\n    [class.small]=\"size === 'small'\"\n    [class.blue]=\"activeValueIndex === $index\"\n    [class.neutral]=\"activeValueIndex !== $index\"\n    type=\"button\"\n    (click)=\"onClick($index)\"\n    [options]=\"{\n      style: buttonStyle,\n      size,\n    }\"\n    [disabled]=\"disabled\">\n    {{ option.label }}\n  </button>\n  @if (!$last) {\n    <div\n      class=\"divider\"\n      [class.small]=\"size === 'small'\"\n      [class.visible]=\"activeValueIndex !== $index && activeValueIndex !== $index+1\"\n      [class.hidden]=\"hideDivider\">\n  </div>\n  }\n}\n",
      styles: [".dt-button{font-family:roboto;font-weight:600;font-size:.75rem;line-height:1.25rem}:host{display:flex;height:fit-content;flex-direction:row;justify-content:center;align-items:center;background-color:var(--interactive-background);border-radius:.25rem}.dt-button{flex:1}.divider{width:1px;height:1.125rem;background-color:var(--interactive-background);margin-left:.125rem;margin-right:.125rem}.divider.small{height:.875rem}.visible{background-color:var(--ui-data-entry)}.neutral{background-color:var(--interactive-background);color:var(--text-secondary)}.blue{border-right:none}.hidden{display:none}\n"]
    }]
  }], null, {
    size: [{
      type: Input
    }],
    buttonStyle: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    activeValueIndex: [{
      type: Input
    }],
    hideDivider: [{
      type: Input
    }]
  });
})();
class FlySegmentedControllerModule {
  static {
    this.ɵfac = function FlySegmentedControllerModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FlySegmentedControllerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FlySegmentedControllerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, FormsModule, ReactiveFormsModule, FlyButtonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlySegmentedControllerModule, [{
    type: NgModule,
    args: [{
      declarations: [FlySegmentedControllerComponent],
      imports: [CommonModule, FormsModule, ReactiveFormsModule, FlyButtonModule],
      exports: [FlySegmentedControllerComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of avcloud-ui-common/segmented-controller
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FlySegmentedControllerComponent, FlySegmentedControllerModule };
