import { HttpClient } from '@angular/common/http';
import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { environment } from '@environment';
import { Icons, FlightIcons } from '@garmin-avcloud/avcloud-ui-common/icon';
import { AuthService, CookieService } from '@garmin-avcloud/avcloud-web-utils';
import { FlightRouteControllerService, RouteComputedLeg, RouteComputedLegLocationType, RouteLeg, UnifiedTokenRequest, UnifiedTokenResponse } from '@generated/flight-route-service';
import { TOKEN_SEARCH_TYPES } from '@shared/constants/flights/flights-constants';
import { CrosshairIcon } from '@shared/constants/icons';
import { SettingsWaypointsWaypointModel } from '@shared/models/settings/waypoints/settings-waypoints-waypoint.model';
import { FlightOrchestratorUserSettingsService } from '@shared/services/flight-orchestrator/user-settings/flight-orchestrator-user-settings.service';
import { Subscription, debounceTime, distinctUntilChanged, forkJoin, tap } from 'rxjs';

interface SearchFormFormGroup {
  searchText: FormControl<string | null>;
}

@Component({
  selector: 'pilot-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  static readonly keyConst: string = 'searchKey';
  @Output() readonly tokenSelected = new EventEmitter<RouteComputedLeg>();
  @Input() searchText?: string;
  @Input() searchOrigin: RouteLeg | RouteComputedLeg | undefined;
  @Input() viewMode: boolean = false;

  formGroup: FormGroup<SearchFormFormGroup>;
  settingsWaypoint: SettingsWaypointsWaypointModel[];
  tokenSearchResults: RouteComputedLeg[];
  numbOfCookies: number;
  errorMsg: string | null = null;
  isAuthenticated: boolean = false;

  readonly icons = {
    crosshair: CrosshairIcon,
    plus: Icons.Plus,
    search: Icons.Search,
    airportIcon: FlightIcons.Airports.Towered
  };

  private currentSearchRequest = new Subscription();

  constructor(
    private readonly authService: AuthService,
    readonly fb: FormBuilder,
    readonly flightRouteService: FlightRouteControllerService,
    private readonly http: HttpClient,
    readonly cookieService: CookieService,
    readonly orchestratorUserSetting: FlightOrchestratorUserSettingsService
  ) {}

  ngOnInit(): void {
    const searchTextControl = new FormControl<string | null>(null);
    this.formGroup = new FormGroup({
      searchText: searchTextControl
    });

    const searchDebounceTimeInMS = 200;
    searchTextControl.valueChanges
      .pipe(
        tap(() => this.currentSearchRequest.unsubscribe()),
        debounceTime(searchDebounceTimeInMS),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((newSearchText) => this.handleSearchTextChanged(newSearchText));

    this.numbOfCookies = 0;
    if (this.searchText != null) {
      this.setFormGroup(this.searchText);
    }

    this.authService.isAuthenticated()
      .subscribe({
        next: (isAuthenticated) => {
          this.isAuthenticated = isAuthenticated;
        },
        error: (e) => {
          console.error('authService error:', e);
        }
      });
  }

  private handleSearchTextChanged(newSearchText: string | null): void {
    if (newSearchText == null || newSearchText.length === 0) {
      this.tokenSearchResults = [];
      return;
    }

    this.tokenSearch(newSearchText);
  }

  tokenSearch(searchTerm: string): void {
    this.errorMsg = null;

    /***********************************************************************************
     *
     *      The Token request to be sent to the API will consist of the input string
     *      and the desired location types of numbers: 1-8, 10-11, 13-15, 31
     *
     ***********************************************************************************/

    const reqToken: UnifiedTokenRequest = {
      name: searchTerm,
      exactSearch: false,
      lat: this.searchOrigin?.lat,
      lon: this.searchOrigin?.lon,
      desiredLocationTypes: TOKEN_SEARCH_TYPES
    };

    if (this.isAuthenticated) {
      this.currentSearchRequest = forkJoin([
        this.flightRouteService.unifiedTokenSearchPost(reqToken),
        this.orchestratorUserSetting.getWaypointsByName(searchTerm)
      ]).subscribe(([searchResponse, userWaypointsResponse]) => {
        this.tokenSearchResults = searchResponse.resultsList ?? [];

        if (userWaypointsResponse != null) {
          for (const waypoint of userWaypointsResponse) {
            this.tokenSearchResults.push({
              identifier: waypoint.name,
              locationType: RouteComputedLegLocationType.USER_WAYPOINT,
              lat: waypoint.lat,
              lon: waypoint.lon
            });
          }
        }
      });
    } else {
      this.currentSearchRequest =
        this.http.post<UnifiedTokenResponse>(
          `${environment.garmin.aviation.workerApiHost}/flights/token-search-unauthenticated`,
          reqToken,
          {
            headers: { 'content-type': 'application/json;charset=UTF-8' }
          })
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((searchResponse) => {
            this.tokenSearchResults = searchResponse.resultsList ?? [];
          });
    }
  }

  selectResult(token: RouteComputedLeg): void {
    this.tokenSelected.emit(token);
    this.formGroup.reset();
  }

  createCookie(newCookieValue: string): void {
    //here we check out to see if a cookie exists with the key
    //if it does we concat with a comma to delimit
    //if it doesn't we just set the cookie like normal
    if (this.cookieService.has(SearchFormComponent.keyConst)) {
      if (this.checkCookies(newCookieValue)) {
        let cookieToConcat = JSON.stringify(
          this.cookieService.get(SearchFormComponent.keyConst)
        );
        cookieToConcat = cookieToConcat.concat(',');
        cookieToConcat = cookieToConcat.concat(newCookieValue);
        this.cookieService.set(SearchFormComponent.keyConst, cookieToConcat);
      }
    } else {
      this.cookieService.set(SearchFormComponent.keyConst, newCookieValue);
    }
  }

  checkCookies(checkMe: string): boolean {
    let cookiesArray: string[] = [];
    const checkFix: string = checkMe.replace(/"/g, '');
    let cookieHolder: string;
    const keyToUse = SearchFormComponent.keyConst;
    cookieHolder = JSON.stringify(this.cookieService.get(keyToUse)).replace(
      /"/g,
      ''
    );
    cookieHolder = cookieHolder.replace(/\\/g, '');
    cookiesArray = cookieHolder.split(',');

    for (const cookie of cookiesArray) {
      if (cookie === checkFix) {
        return false;
      }
    }
    return true;
  }

  setFormGroup(text: string): void {
    this.formGroup.setValue({ searchText: text });
  }
}
