import { HttpClient } from '@angular/common/http';
import { Pipe, type PipeTransform, SecurityContext, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { type Observable, map, of } from 'rxjs';

/**
   A pipe which forwards a URL through the HttpClient, in order to provide
   the necessary headers on the request for that resource.
*/
@Pipe({
  name: 'flySecure',
  standalone: true,
})
export class SecurePipe implements PipeTransform {
  private readonly httpClient = inject(HttpClient);
  private readonly domSanitizer = inject(DomSanitizer);

  transform(url?: string): Observable<string | null> {
    if (url == null) return of(null);
    return this.httpClient
      .get(url, { responseType: 'blob' })
      .pipe(
        map((data) =>
          this.domSanitizer.sanitize(
            SecurityContext.RESOURCE_URL,
            this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(data)),
          ),
        ),
      );
  }
}
