import { CommonModule, TitleCasePipe } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { FlyCardModule } from '@garmin-avcloud/avcloud-ui-common/card';
import { FlightIcons, FlyIconModule } from '@garmin-avcloud/avcloud-ui-common/icon';
import { isStringNonEmpty } from '@garmin-avcloud/avcloud-web-utils';
import { AirportSearchResult } from '@shared/models/airport/search/airport-search-result.model';
import { ReplacePipe } from '@shared/pipes/replace/replace.pipe';
import { ExtendedTitleCasePipe } from '../../../pipes/extended-title-case/extended-title-case.pipe';

@Component({
  selector: 'pilot-airport-search-card',
  templateUrl: './airport-search-card.component.html',
  styleUrls: ['./airport-search-card.component.scss'],
  standalone: true,
  imports: [CommonModule, ExtendedTitleCasePipe, FlyCardModule, FlyIconModule, ReplacePipe]
})
export class AirportSearchCardComponent {
  readonly airport = input.required<AirportSearchResult>();

  private readonly titleCasePipe = new TitleCasePipe();
  readonly FlightIcons = FlightIcons;

  readonly location = computed(() => {
    const locationList = [];

    if (isStringNonEmpty(this.airport().city)) {
      locationList.push(`${this.titleCasePipe.transform(this.airport().city)}`);
    }

    if (isStringNonEmpty(this.airport().state)) {
      locationList.push(`${this.airport().state}`);
    }

    /**
     * Special case for USA airports. ACL stores the country name with the region
     * for airports in the US but we only need the name. Example: N CEN USA
     */
    const isUsAirport = this.airport().countryName?.includes('USA') ?? false;
    if (isUsAirport) {
      locationList.push('USA');
    } else {
      const country = this.titleCasePipe.transform(this.airport().countryName);
      locationList.push(country);
    }

    return locationList.join(', ');
  });
}
