import { AirportModel } from '@shared/models/airport/airport.model';

export enum AirportDataSection {
  CHART = 'CHART',
  CHARTSUPPLEMENT = 'CHARTSUPPLEMENT',
  FORECASTDISCUSSION = 'FORECASTDISCUSSION',
  FUEL = 'FUEL',
  METAR = 'METAR',
  MOS = 'MOS',
  NOTAM = 'NOTAM',
  RUNWAY = 'RUNWAY',
  TAF = 'TAF',
  WIND = 'WIND',
  ADBRESPONSE = 'ADBRESPONSE',
  AOPARESPONSE = 'AOPARESPONSE',
  AIRSIGRESPONSE = 'AIRSIGRESPONSE',
  NOTAMSSUMMARYRESPONSE = 'NOTAMSSUMMARYRESPONSE'
}

export const AirportDataSectionPropertyMap = new Map<AirportDataSection, keyof AirportModel>([
  [AirportDataSection.CHART, 'charts'],
  [AirportDataSection.CHARTSUPPLEMENT, 'chartSupplements'],
  [AirportDataSection.FORECASTDISCUSSION, 'forecastDiscussion'],
  [AirportDataSection.FUEL, 'fuel'],
  [AirportDataSection.METAR, 'metar'],
  [AirportDataSection.MOS, 'mos'],
  [AirportDataSection.NOTAM, 'notamsResponse'],
  [AirportDataSection.TAF, 'taf'],
  [AirportDataSection.WIND, 'winds'],
  [AirportDataSection.ADBRESPONSE, 'adbResponse'],
  [AirportDataSection.AOPARESPONSE, 'aopaResponse'],
  [AirportDataSection.AIRSIGRESPONSE, 'airsigs'],
  [AirportDataSection.NOTAMSSUMMARYRESPONSE, 'notamsSummaryResponse']
]);

export const WxDataSectionPropertyMap = new Map<AirportDataSection, keyof AirportModel>([
  [AirportDataSection.FORECASTDISCUSSION, 'forecastDiscussion'],
  [AirportDataSection.METAR, 'metar'],
  [AirportDataSection.MOS, 'mos'],
  [AirportDataSection.TAF, 'taf']
]);
