/**
 * FlightRouteService
 * Service to calculate flight plan routes
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-Services-Merlin-Navin-Valli-Said@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ADBCycleResponse } from '../model/adb-cycle-response.model';
// @ts-ignore
import { ARTCCInfo } from '../model/artcc-info.model';
// @ts-ignore
import { ARTCCQuery } from '../model/artcc-query.model';
// @ts-ignore
import { AltitudeSurveyUnifiedRequest } from '../model/altitude-survey-unified-request.model';
// @ts-ignore
import { AltitudeSurveyUnifiedResponse } from '../model/altitude-survey-unified-response.model';
// @ts-ignore
import { ArincFlightRouteResponse } from '../model/arinc-flight-route-response.model';
// @ts-ignore
import { LatLonRequest } from '../model/lat-lon-request.model';
// @ts-ignore
import { LatLonResponse } from '../model/lat-lon-response.model';
// @ts-ignore
import { ModifyRouteRequest } from '../model/modify-route-request.model';
// @ts-ignore
import { OptimizedRouteRequest } from '../model/optimized-route-request.model';
// @ts-ignore
import { OptimizedRouteResponse } from '../model/optimized-route-response.model';
// @ts-ignore
import { PowerSurveyResponse } from '../model/power-survey-response.model';
// @ts-ignore
import { ProcedureRequest } from '../model/procedure-request.model';
// @ts-ignore
import { ProcedureResponse } from '../model/procedure-response.model';
// @ts-ignore
import { ReverseLatLonResponse } from '../model/reverse-lat-lon-response.model';
// @ts-ignore
import { Route } from '../model/route.model';
// @ts-ignore
import { RouteRequest } from '../model/route-request.model';
// @ts-ignore
import { UnifiedAirwayResponse } from '../model/unified-airway-response.model';
// @ts-ignore
import { UnifiedAirwaySearchRequest } from '../model/unified-airway-search-request.model';
// @ts-ignore
import { UnifiedArincRequest } from '../model/unified-arinc-request.model';
// @ts-ignore
import { UnifiedTokenRequest } from '../model/unified-token-request.model';
// @ts-ignore
import { UnifiedTokenResponse } from '../model/unified-token-response.model';
// @ts-ignore
import { ValidationResponse } from '../model/validation-response.model';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { FlightRouteServiceConfiguration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class FlightRouteControllerService {

    protected basePath = 'https://avcloud-api-test.garmin.com/flightRouteService';
    public defaultHeaders = new HttpHeaders();
    public configuration = new FlightRouteServiceConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: FlightRouteServiceConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Check if flight plan is valid
     * This interface will be used by the Flights Domain Orchestrator to validate a flight plan before attempting to FILE that with the relevant authorities. In addition to validation of the route, this function also provides the set of ACC boundary crossings (currently only FIR) that are required for filing.
     * @param routeRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public _1(routeRequest: RouteRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<ValidationResponse>;
    public _1(routeRequest: RouteRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<ValidationResponse>>;
    public _1(routeRequest: RouteRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<ValidationResponse>>;
    public _1(routeRequest: RouteRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (routeRequest === null || routeRequest === undefined) {
            throw new Error('Required parameter routeRequest was null or undefined when calling _1.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/flightroute/validate-unified`;
        return this.httpClient.request<ValidationResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: routeRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries Flight Route Service to determine the current ADB3 database version in use
     * Gets the current ADB3 version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adbCycleGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<ADBCycleResponse>;
    public adbCycleGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<ADBCycleResponse>>;
    public adbCycleGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<ADBCycleResponse>>;
    public adbCycleGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/flightroute/adb3-version`;
        return this.httpClient.request<ADBCycleResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Performance data for a range of altitudes
     * Generate a flight plan and then return summarized performance output for a range of altitudes.
     * @param altitudeSurveyUnifiedRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public altitudeSurveyUnifiedPost(altitudeSurveyUnifiedRequest: AltitudeSurveyUnifiedRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<AltitudeSurveyUnifiedResponse>;
    public altitudeSurveyUnifiedPost(altitudeSurveyUnifiedRequest: AltitudeSurveyUnifiedRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<AltitudeSurveyUnifiedResponse>>;
    public altitudeSurveyUnifiedPost(altitudeSurveyUnifiedRequest: AltitudeSurveyUnifiedRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<AltitudeSurveyUnifiedResponse>>;
    public altitudeSurveyUnifiedPost(altitudeSurveyUnifiedRequest: AltitudeSurveyUnifiedRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (altitudeSurveyUnifiedRequest === null || altitudeSurveyUnifiedRequest === undefined) {
            throw new Error('Required parameter altitudeSurveyUnifiedRequest was null or undefined when calling altitudeSurveyUnifiedPost.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/flightroute/altitude-survey-unified`;
        return this.httpClient.request<AltitudeSurveyUnifiedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: altitudeSurveyUnifiedRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a flight plan
     * Creates a flight plan
     * @param routeRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUnifiedPost(routeRequest: RouteRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<Route>;
    public createUnifiedPost(routeRequest: RouteRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<Route>>;
    public createUnifiedPost(routeRequest: RouteRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<Route>>;
    public createUnifiedPost(routeRequest: RouteRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (routeRequest === null || routeRequest === undefined) {
            throw new Error('Required parameter routeRequest was null or undefined when calling createUnifiedPost.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/flightroute/create-unified`;
        return this.httpClient.request<Route>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: routeRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get latitude and longitude from waypoint(s)
     * This interface requires either one waypoint with place (position and/or identifier and country code), bearing, and distance or two waypoints with place (position and/or identifier and country code) and bearing. Using these values the user waypoint position is calculated and returned. The input identifier and country code are used for finding the magnetic variation of the waypoint. If no identifier is provided, the magnetic variation interpolated value is calculated for the input position.
     * @param latLonRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public latLongSearchPost(latLonRequest: LatLonRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<LatLonResponse>;
    public latLongSearchPost(latLonRequest: LatLonRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<LatLonResponse>>;
    public latLongSearchPost(latLonRequest: LatLonRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<LatLonResponse>>;
    public latLongSearchPost(latLonRequest: LatLonRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (latLonRequest === null || latLonRequest === undefined) {
            throw new Error('Required parameter latLonRequest was null or undefined when calling latLongSearchPost.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/flightroute/lat-lon-search`;
        return this.httpClient.request<LatLonResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: latLonRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modify a flight plan
     * Modify a flight plan
     * @param modifyRouteRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modifyUnifiedPost(modifyRouteRequest: ModifyRouteRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<Route>;
    public modifyUnifiedPost(modifyRouteRequest: ModifyRouteRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<Route>>;
    public modifyUnifiedPost(modifyRouteRequest: ModifyRouteRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<Route>>;
    public modifyUnifiedPost(modifyRouteRequest: ModifyRouteRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (modifyRouteRequest === null || modifyRouteRequest === undefined) {
            throw new Error('Required parameter modifyRouteRequest was null or undefined when calling modifyUnifiedPost.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/flightroute/modify-unified`;
        return this.httpClient.request<Route>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: modifyRouteRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generate optimized routes
     * Generate a time optimized route and a fuel optimized route for the given origin and destination airport. If the input avoidSIDs/STARs is TRUE, the routes do not use SIDs/STARs, regardless of whether SID/STAR is specified in the request.
     * @param optimizedRouteRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public optimizedRoutingPost(optimizedRouteRequest: OptimizedRouteRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<OptimizedRouteResponse>;
    public optimizedRoutingPost(optimizedRouteRequest: OptimizedRouteRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<OptimizedRouteResponse>>;
    public optimizedRoutingPost(optimizedRouteRequest: OptimizedRouteRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<OptimizedRouteResponse>>;
    public optimizedRoutingPost(optimizedRouteRequest: OptimizedRouteRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (optimizedRouteRequest === null || optimizedRouteRequest === undefined) {
            throw new Error('Required parameter optimizedRouteRequest was null or undefined when calling optimizedRoutingPost.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/flightroute/optimized-routing`;
        return this.httpClient.request<OptimizedRouteResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: optimizedRouteRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Performance data for a range of power settings
     * Generate a flight plan and then return summarized performance output for a range of power settings
     * @param routeRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public powerSurveyPost(routeRequest: RouteRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<PowerSurveyResponse>;
    public powerSurveyPost(routeRequest: RouteRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<PowerSurveyResponse>>;
    public powerSurveyPost(routeRequest: RouteRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<PowerSurveyResponse>>;
    public powerSurveyPost(routeRequest: RouteRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (routeRequest === null || routeRequest === undefined) {
            throw new Error('Required parameter routeRequest was null or undefined when calling powerSurveyPost.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/flightroute/power-survey`;
        return this.httpClient.request<PowerSurveyResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: routeRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Look up arrival and departure procedures by Airport identifier
     * This interface is used by the Front-End Flight Planning UI Form(s) to used to populate Pick-List entries and draw map polygons for SID/STAR Procedures.
     * @param procedureRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public procedureSearchPostUnified(procedureRequest: ProcedureRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<ProcedureResponse>;
    public procedureSearchPostUnified(procedureRequest: ProcedureRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<ProcedureResponse>>;
    public procedureSearchPostUnified(procedureRequest: ProcedureRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<ProcedureResponse>>;
    public procedureSearchPostUnified(procedureRequest: ProcedureRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (procedureRequest === null || procedureRequest === undefined) {
            throw new Error('Required parameter procedureRequest was null or undefined when calling procedureSearchPostUnified.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/flightroute/procedure-search-unified`;
        return this.httpClient.request<ProcedureResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: procedureRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Query ARTCC information based on the ID, FIR/UIR or Airport Identifier
     * This interface is used by Filing Service to determine informaton about the filing authority for a given flight
     * @param aRTCCQuery 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public queryARTCCInfo(aRTCCQuery: ARTCCQuery, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<ARTCCInfo>;
    public queryARTCCInfo(aRTCCQuery: ARTCCQuery, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<ARTCCInfo>>;
    public queryARTCCInfo(aRTCCQuery: ARTCCQuery, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<ARTCCInfo>>;
    public queryARTCCInfo(aRTCCQuery: ARTCCQuery, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (aRTCCQuery === null || aRTCCQuery === undefined) {
            throw new Error('Required parameter aRTCCQuery was null or undefined when calling queryARTCCInfo.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/flightroute/query-artcc`;
        return this.httpClient.request<ARTCCInfo>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: aRTCCQuery,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get distance and bearing from NAVAID(s)
     * This interface requires one waypoint where the NAVAID and position are set. The distance and bearing from the position to the NAVAID is computed and returned. Optionally a second waypoint of the same format may be provided and two sets of distance and bearings will be returned.
     * @param latLonRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reverseLatLongSearchPost(latLonRequest: LatLonRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<ReverseLatLonResponse>;
    public reverseLatLongSearchPost(latLonRequest: LatLonRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<ReverseLatLonResponse>>;
    public reverseLatLongSearchPost(latLonRequest: LatLonRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<ReverseLatLonResponse>>;
    public reverseLatLongSearchPost(latLonRequest: LatLonRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (latLonRequest === null || latLonRequest === undefined) {
            throw new Error('Required parameter latLonRequest was null or undefined when calling reverseLatLongSearchPost.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/flightroute/reverse-lat-lon-search`;
        return this.httpClient.request<ReverseLatLonResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: latLonRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Look up waypoint from the ADB by name
     * This interface is used by the Front-End Flight Planning UI Form(s) to interpret and validate user input. The interface is used to populate available Airways.
     * @param unifiedAirwaySearchRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unifiedAirwaySearchPost(unifiedAirwaySearchRequest: UnifiedAirwaySearchRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<UnifiedAirwayResponse>;
    public unifiedAirwaySearchPost(unifiedAirwaySearchRequest: UnifiedAirwaySearchRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<UnifiedAirwayResponse>>;
    public unifiedAirwaySearchPost(unifiedAirwaySearchRequest: UnifiedAirwaySearchRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<UnifiedAirwayResponse>>;
    public unifiedAirwaySearchPost(unifiedAirwaySearchRequest: UnifiedAirwaySearchRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (unifiedAirwaySearchRequest === null || unifiedAirwaySearchRequest === undefined) {
            throw new Error('Required parameter unifiedAirwaySearchRequest was null or undefined when calling unifiedAirwaySearchPost.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/flightroute/unified-airway-search`;
        return this.httpClient.request<UnifiedAirwayResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: unifiedAirwaySearchRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generate ARINC702A string for flight plan
     * Returns the ARINC 702A string if success
     * @param unifiedArincRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unifiedArincStringPost(unifiedArincRequest: UnifiedArincRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<ArincFlightRouteResponse>;
    public unifiedArincStringPost(unifiedArincRequest: UnifiedArincRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<ArincFlightRouteResponse>>;
    public unifiedArincStringPost(unifiedArincRequest: UnifiedArincRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<ArincFlightRouteResponse>>;
    public unifiedArincStringPost(unifiedArincRequest: UnifiedArincRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (unifiedArincRequest === null || unifiedArincRequest === undefined) {
            throw new Error('Required parameter unifiedArincRequest was null or undefined when calling unifiedArincStringPost.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/flightroute/unified-arinc-string`;
        return this.httpClient.request<ArincFlightRouteResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: unifiedArincRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Look up routeLeg from the ADB by name
     * This interface is used by the Front-End Flight Planning UI Form(s) to interpret and validate user input, such as partial or full Airport names.
     * @param unifiedTokenRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unifiedTokenSearchPost(unifiedTokenRequest: UnifiedTokenRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<UnifiedTokenResponse>;
    public unifiedTokenSearchPost(unifiedTokenRequest: UnifiedTokenRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<UnifiedTokenResponse>>;
    public unifiedTokenSearchPost(unifiedTokenRequest: UnifiedTokenRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<UnifiedTokenResponse>>;
    public unifiedTokenSearchPost(unifiedTokenRequest: UnifiedTokenRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (unifiedTokenRequest === null || unifiedTokenRequest === undefined) {
            throw new Error('Required parameter unifiedTokenRequest was null or undefined when calling unifiedTokenSearchPost.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/flightroute/unified-token-search`;
        return this.httpClient.request<UnifiedTokenResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: unifiedTokenRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
