/**
 * FlightRouteService
 * Service to calculate flight plan routes
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-Services-Merlin-Navin-Valli-Said@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * En route user preference settings
 */
export interface RouteSettings { 
    /**
     * Flag indicates whether to score routes based on the loaded wind data. Defaults to true
     */
    useWinds: boolean;
    /**
     * Flag indicates whether to avoid flying over water. Defaults to false
     */
    avoidWater: boolean;
    /**
     * Radius around the start/end airport to use for creating rough swath for routing (meters). Defaults to 100 nautical miles. Valid range is 0 - 185200000
     */
    startEndRadius: number;
    /**
     * Maximum altitude to generate routes (meters). No routes above the maximum altitude will be used. It is also used to limit procedures, if they would require flying above this height.
     */
    maxAltitude: number;
    /**
     * Rough estimate of flight path width to use when avoiding routing over water (meters). Defaults to 10 nautical miles.
     */
    flightPathWidth: number;
    /**
     * Cruise speed of aircraft (meters per second).
     */
    cruiseSpdMps: number;
    /**
     * List of controlled/restricted airspace types that should be avoided. List is based on ACL type ADB_sua_class_t8 in adb_pub_lib_type.h, and note that the list may have more entries than the UI needs, in addition one or more type may be avoided all the time, regardless of this avoidance setting (for example Prohibited)
     */
    airspaceAvoidanceList?: Array<RouteSettingsAirspaceAvoidanceList>;
}
export enum RouteSettingsAirspaceAvoidanceList {
    CLASS_C_AIRSPACE = 'CLASS_C_AIRSPACE',
    ICAO_CONTROL_AREA = 'ICAO_CONTROL_AREA',
    ICAO_TERMINAL_CONTROL_AREA = 'ICAO_TERMINAL_CONTROL_AREA',
    TERMINAL_RADAR_SERVICE_AREA = 'TERMINAL_RADAR_SERVICE_AREA',
    CLASS_B_AIRSPACE = 'CLASS_B_AIRSPACE',
    CONTROL_ZONE = 'CONTROL_ZONE',
    CLASS_F_AIRSPACE = 'CLASS_F_AIRSPACE',
    AIR_TRAFFIC_ZONE = 'AIR_TRAFFIC_ZONE',
    CLASS_D_AIRSPACE = 'CLASS_D_AIRSPACE',
    CLASS_E_AIRSPACE = 'CLASS_E_AIRSPACE',
    CLASS_A_AIRSPACE = 'CLASS_A_AIRSPACE',
    RADAR_AREA_ZONE = 'RADAR_AREA_ZONE',
    SPECIAL_RULE_AIRSPACE = 'SPECIAL_RULE_AIRSPACE',
    TRAFFIC_INFORMATION_ZONE = 'TRAFFIC_INFORMATION_ZONE',
    AIRWAY_AIRSPACE = 'AIRWAY_AIRSPACE',
    CLASS_G_AIRSPACE = 'CLASS_G_AIRSPACE',
    AIRPORT_RADAR_SERVICE_AREA = 'AIRPORT_RADAR_SERVICE_AREA',
    NON_APT_CA_UTA = 'NON_APT_CA_UTA',
    HELICOPTER_PROTECTED_ZONE = 'HELICOPTER_PROTECTED_ZONE',
    HELICOPTER_TRAFFIC_ZONE = 'HELICOPTER_TRAFFIC_ZONE',
    MILITARY_AIR_TRAFFIC_ZONE = 'MILITARY_AIR_TRAFFIC_ZONE',
    MILITARY_CONTROL_ZONE = 'MILITARY_CONTROL_ZONE',
    MILITARY_TERMINAL_AREA = 'MILITARY_TERMINAL_AREA',
    OCEANIC_CONTROL_AREA_NON_APT = 'OCEANIC_CONTROL_AREA_NON_APT',
    SPECIAL_RULES_ZONE = 'SPECIAL_RULES_ZONE',
    TERMINAL_CONTROL_AREA = 'TERMINAL_CONTROL_AREA',
    UPPER_CONTROL_AREA = 'UPPER_CONTROL_AREA',
    UPPER_CONTROL_AREA_NON_APT = 'UPPER_CONTROL_AREA_NON_APT',
    POSITIVE_CONTROL_AREA = 'POSITIVE_CONTROL_AREA',
    TRAFFIC_INFORMATION_AREA = 'TRAFFIC_INFORMATION_AREA',
    CONTROL_AIRSPACE_MODE_C = 'CONTROL_AIRSPACE_MODE_C',
    MANDATORY_BROADCAST_ZONE = 'MANDATORY_BROADCAST_ZONE',
    COMMON_FREQUENCY_ZONE = 'COMMON_FREQUENCY_ZONE',
    RADIO_MANDATORY_ZONE = 'RADIO_MANDATORY_ZONE',
    TRANSPONDER_MANDATORY_ZONE = 'TRANSPONDER_MANDATORY_ZONE',
    FLIGHT_INFORMATION_ZONE = 'FLIGHT_INFORMATION_ZONE',
    FREQUENCY_MONITORING_ZONE = 'FREQUENCY_MONITORING_ZONE',
    UNKNOWN_CONTROL_AIRSPACE = 'UNKNOWN_CONTROL_AIRSPACE',
    ALERT_AREA = 'ALERT_AREA',
    CAUTION_AREA = 'CAUTION_AREA',
    DANGER_AREA = 'DANGER_AREA',
    MILITARY_OPERATION_AREA = 'MILITARY_OPERATION_AREA',
    PROHIBITED_AREA = 'PROHIBITED_AREA',
    RESTRICTED_AREA = 'RESTRICTED_AREA',
    TRAINING_AREA = 'TRAINING_AREA',
    UNKNOWN_AREA = 'UNKNOWN_AREA',
    WARNING_AREA = 'WARNING_AREA',
    AIR_DEFENSE_IDENTIFICATION_ZONE = 'AIR_DEFENSE_IDENTIFICATION_ZONE',
    TEMPORARY_TRA_TSA = 'TEMPORARY_TRA_TSA',
    PARACHUTE_JUMP_AREA = 'PARACHUTE_JUMP_AREA',
    NATIONAL_SECURITY_AREA = 'NATIONAL_SECURITY_AREA',
    LONG_TERM_TFR = 'LONG_TERM_TFR',
    BUFFER_ZONE = 'BUFFER_ZONE',
    CROSS_BORDER_AREA = 'CROSS_BORDER_AREA',
    TEMP_SEGREGATED_AREA = 'TEMP_SEGREGATED_AREA',
    ADVISORY_AREA = 'ADVISORY_AREA',
    NATURE_CONSERVATION_AREA = 'NATURE_CONSERVATION_AREA',
    AVOID_OVERFLYING_AREA = 'AVOID_OVERFLYING_AREA',
    FLIGHT_INFORMATION_SERVICE = 'FLIGHT_INFORMATION_SERVICE',
    AEROBATIC_PRACTICE_AREA = 'AEROBATIC_PRACTICE_AREA',
    UNKNOWN_RESTRICTED_AREA = 'UNKNOWN_RESTRICTED_AREA'
};



