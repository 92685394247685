/**
 * FlightOrchestratorService
 * flightOrchestratorService
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-ProdExp-Cloud-Connectivity@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A snapshot of several flight data fields as they appear in the most recently filed flight plan. Will be null if the flight is not filed.
 */
export interface FiledFlightPlanDataSnapshot { 
    /**
     * (enum) The flight rules
     */
    flightRules?: FiledFlightPlanDataSnapshotFlightRules;
    /**
     * The name of the pilot in command
     */
    pilotInCommandName?: string;
    /**
     * The address of the pilot in command
     */
    pilotInCommandAddress?: string;
    /**
     * The phone number of the pilot in command
     */
    pilotInCommandPhoneNumber?: string;
    /**
     * The aircraft tail identifier
     */
    aircraftTail?: string;
    /**
     * ICAO aircraft equipment code(s), as a comma-delimited string
     */
    aircraftEquipment?: string;
    /**
     * Radio call sign for the flight
     */
    callSign?: string;
    /**
     * The number of people on board
     */
    personsOnBoard?: number;
    /**
     * General remarks for the flight plan
     */
    remarks?: string;
}
export enum FiledFlightPlanDataSnapshotFlightRules {
    VFR = 'VFR',
    IFR = 'IFR',
    YFR = 'YFR',
    ZFR = 'ZFR',
    SFRA = 'SFRA'
};



