<div class="not-configured">
  <img
  alt="Performance Aircraft"
  src="assets/illustrations/enhanced-performance.png">
  <span>Your aircraft is not configured with enhanced performance data.</span>
  <button
    class="not-configured-button"
    role="button"
    (click)="configureAircraft()"
    type="button"
    flyButton>
    Configure Aircraft
  </button>
</div>
