import {
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  type OnInit,
  Output,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Icons } from '@garmin-avcloud/avcloud-ui-common/icon';
import { RouteStageType } from '@shared/enums/flights/route-stage-type.enum';
import { ProcedureOverlayService } from 'projects/avcloud-pilotweb/src/app/features/flights/services/procedure-overlay/procedure-overlay.service';
import { ProcedureOverlayMode } from 'projects/avcloud-pilotweb/src/app/features/flights/tokens/procedure-overlay-mode/procedure-overlay-mode.token';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'pilot-waypoint-options',
  templateUrl: './waypoint-options.component.html',
  styleUrls: ['./waypoint-options.component.scss']
})
export class WaypointOptionsComponent implements OnInit {
  @Input() allowInput: boolean;
  @Input() hasAirways = true; // an airway search found airways associated with this waypoint
  @Input() hasDepartureProcedures = false; // origin airport has procedures
  @Input() hasArrivalProcedures = false; // destination airport has procedures
  @Input() nextLegIsAirway = false; // this waypoint is an airway entry
  @Input() routeStage: RouteStageType = RouteStageType.ENROUTE;
  @Input() button: Element;

  // TODO: consider an API where this component can handle the selected operation on its own (see addProcedure)
  @Output() readonly addAfter: EventEmitter<void> = new EventEmitter();
  @Output() readonly addBefore: EventEmitter<void> = new EventEmitter();
  @Output() readonly loadAirway: EventEmitter<void> = new EventEmitter();
  @Output() readonly remove: EventEmitter<void> = new EventEmitter();
  @Output() readonly replace: EventEmitter<void> = new EventEmitter();

  readonly Icons = Icons;
  readonly RouteStageType = RouteStageType;
  readonly ProcedureOverlayMode = ProcedureOverlayMode;

  showPopup = false;

  private readonly destroyRef = inject(DestroyRef);
  private readonly el = inject(ElementRef);
  protected readonly procedureOverlayService = inject(ProcedureOverlayService);

  ngOnInit(): void {
    // Close popup when clicking elsewhere on the page (except the button that opens this popup)
    fromEvent(document, 'click')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event: any) => {
        if (this.el.nativeElement.contains(event.target) === false
            && !this.button.contains(event.target)) {
          this.hide();
        }
        event.stopPropagation();
      });
  }

  hide(): void {
    this.showPopup = false;
  }

  hideAndEmit(out: EventEmitter<void>): void {
    this.hide();
    out.emit();
  }

  toggleVisibility(): void {
    this.showPopup = this.allowInput && !this.showPopup;
  }
}
